import React, { useEffect } from "react";
import "../../resources/themeContent/styles/newTicker.css";

const NewTicker = () => {
  // Sample news data (replace with your actual API response)
  const sampleNewsData = [
    { title: "Breaking news: Company XYZ announces record profits." },
    { title: "Market update: Dow Jones hits all-time high." },
    { title: "Economic report: Unemployment rate drops to 4.5%." },
    // Add more sample news items here
  ];

  return (
    <>
      <div className="news-feed">
        <div className="ticker-row">
          <div className="ticker-content">
            {/* <!-- News items will be dynamically added here --> */}
            {sampleNewsData.map((ticker) => {
              return <p>{ticker.title}</p>;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTicker;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
} from "../../../helpers/ApiHelpers";

import Config from "../../../helpers/Config";
import {
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
  getLanguageCodeFromSession,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const SizeGuide = (props) => {
  const [SizeList, setSizeList] = useState([]);
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  useEffect(() => {
    // declare the data fetching function
    const getSizeList = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          PageNo: 1,
          PageSize: 100,
          recordValueJson: "[]",
        },
      };

      //--Get sizes list
      const sizeResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_SIZE_LIST"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (sizeResponse != null && sizeResponse.data != null) {
        setSizeList(JSON.parse(sizeResponse.data.data));

        console.log(JSON.parse(sizeResponse.data.data));
      }
    };

    // call the function
    getSizeList().catch(console.error);
  }, []);

  useEffect(() => {
    const getDataInUseEffect = async () => {
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["SizeGuide"],
        null
      );
      console.log(arryRespLocalization);
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    getDataInUseEffect();
  }, []);

  return (
    <>
      <div
        className="modal fade sizeGuideModal show"
        style={{ paddingRight: "16px", display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                props.closeSizeGuide();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="fas fa-times"></i>
              </span>
            </button>

            <div className="modal-sizeguide">
              <h3>
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      "Size Guide",
                      "lbl_sizeguide_sizeguidetext"
                    )
                  : "Size Guide"}
                {/* Size Guide */}
              </h3>
              <p>
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      "This is an approximate conversion table to help you find your size.",
                      "lbl_sizeguide_sizeguideinfo"
                    )
                  : "This is an approximate conversion table to help you find your size."}
                {/* This is an approximate conversion table to help you find your
                size. */}
              </p>

              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Home",
                              "lbl_sizeguide_home"
                            )
                          : "Home"}
                        {/* Name */}
                      </th>
                      <th>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Short Name",
                              "lbl_sizeguide_shortname"
                            )
                          : "Short Name"}
                        {/* Short Name */}
                      </th>
                      <th>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Inches",
                              "lbl_sizeguide_inches"
                            )
                          : "Inches"}
                        {/* Inches */}
                      </th>
                      <th>
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Centimeters",
                              "lbl_sizeguide_centimeters"
                            )
                          : "Centimeters"}
                        {/* Centimeters */}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {SizeList?.map((item, idx) => (
                      <tr>
                        <td>{item.Name}</td>
                        <td>{item.ShortName}</td>
                        <td>{item.Inches}</td>
                        <td>{item.Centimeters}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SizeGuide;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import NewProject from "../../resources/themeContent/images/NewProject.svg";
import about2 from "../../resources/themeContent/images/theme-images/about2.jpg";
import signature from "../../resources/themeContent/images/theme-images/signature.png";

import {
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
  getLanguageCodeFromSession,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  useEffect(() => {
    const getDataInUseEffect = async () => {
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["About"],
        null
      );
      console.log(arryRespLocalization);
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    getDataInUseEffect();
  }, []);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"} />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About Us --" />

      <section className="about-area ptb-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <h2>
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "About us",
                        "lbl_about_abouttitle"
                      )
                    : "About us"}
                  {/* About Our Store */}
                </h2>
                <p>
                  Welcome to DXB Car rent , where we make renting cars easy. We
                  are UAE’s leading car rental portal. We connect you to our
                  extensive network of rental partners that offer the best deals
                  and cars to fit your every need. Experience a hassle-free
                  booking process by exploring car rentals on our website and
                  mobile app. Find the best deals for budget, luxury and sports
                  car rentals, car with driver and driver-on-hire services
                  within Dubai, Abu Dhabi, Sharjah and in select cities across
                  the globe. Our car rental service providers offer the lowest
                  prices for a wide range of cars. Choose from flexible rental
                  options ranging between daily, weekly, monthly-basis, as well
                  as customized deals as per your requirement. Travel with ease
                  by experiencing our additional partner services, including
                  24x7 chauffeur and airport transfer service, and on-demand
                  driver service, at the lowest, all-inclusive rates. Choose
                  from a curated range of executive and luxury cars driven by
                  professional chauffeurs, and travel freely at your own pace.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src={NewProject} className="about-img1" alt="image" />
                {/* <img src={about2} className="about-img2" alt="image" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <BestFacilities />
    </>
  );
};

export default About;

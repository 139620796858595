import React, { useEffect, useState } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import {
  BsCheckCircleFill,
  BsStarFill,
  BsFillHeartFill,
  BsFillInfoCircleFill,
} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import ProductQuickView from "./ProductQuickView";
import Config from "../../../helpers/Config";
import ProductRatingStars from "./ProductRatingStars";
import {
  AddCustomerWishList,
  AddProductToCart,
} from "../../../helpers/CartHelper";
import rootAction from "../../../stateManagment/actions/rootAction";
import { useSelector, useDispatch } from "react-redux";
import {
  makeProductShortDescription,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import {
  GetDefaultCurrencySymbol,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import dynamic from "next/dynamic";
import vendorLogo from "../../resources/themeContent/images/vendorlogo.jpeg";
const OwlCarousel = dynamic(import("react-owl-carousel3"));

const ProductsGridTypeOne = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ProductListMainClass, setProductListMainClass] = useState(
    props.ProductListMainClass
  );
  const [ProductsList, setProductsListGrid] = useState([]);
  const [adminPanelBaseURL, setBaseUrl] = useState(Config["ADMIN_BASE_URL"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  const HandleAddToCart = (ProductID, ProductName, Price, defaultImage) => {
    let cartItems = AddProductToCart(
      ProductID,
      ProductName,
      Price,
      0,
      "",
      0,
      "",
      1,
      defaultImage
    );

    // reduxStore.dispatch(rootAction.cartAction.setCustomerCart(cartItems));
    // reduxStore.dispatch(rootAction.cartAction.SetTotalCartItems(JSON.parse(cartItems).length));

    dispatch(rootAction.cartAction.setCustomerCart(cartItems));
    dispatch(
      rootAction.cartAction.SetTotalCartItems(JSON.parse(cartItems).length)
    );
  };

  const reloadProductDetail = (_productId, _categoryName, _productName) => {
    let productDetailUrlFromForceReload = `/${getLanguageCodeFromSession()}/product-detail/${_productId}/${
      replaceWhiteSpacesWithDashSymbolInUrl(_categoryName) ?? "shop"
    }/${replaceWhiteSpacesWithDashSymbolInUrl(_productName)}`;

    if (
      props.ProductDetailPageForceUpload != undefined &&
      props.ProductDetailPageForceUpload != null &&
      props.ProductDetailPageForceUpload == true &&
      _productId != undefined
    ) {
      navigate(productDetailUrlFromForceReload, { replace: true });
      window.location.reload();
    }
  };

  const HandleCustomerWishList = (
    ProductID,
    ProductName,
    Price,
    DiscountedPrice,
    DiscountId,
    IsDiscountCalculated,
    CouponCode,
    defaultImage
  ) => {
    let customerWishList = AddCustomerWishList(
      ProductID,
      ProductName,
      Price,
      DiscountedPrice,
      DiscountId,
      IsDiscountCalculated,
      CouponCode,
      0,
      "",
      0,
      "",
      1,
      defaultImage
    );

    //--store in storage
    localStorage.setItem("customerWishList", customerWishList);
    dispatch(rootAction.cartAction.setCustomerWishList(customerWishList));
  };

  useEffect(() => {
    setProductsListGrid(props.ProductsList);
    console.log("Products list: ");
    console.log(props.ProductsList);
  }, [props.ProductsList]);

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["ProductsGridTypeOne"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  const options = {
    loop: true,
    nav: false,
    dots: true,
    // autoplay: true,
    // autoplayTimeout: 1000,
    // autoplayHoverPause: true,
    items: 1,
    navText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>",
    ],
  };

  return (
    <>
      {ProductsList?.slice(0, 4).map((item, idx) => {
        let urlViewDetailImage = `/${getLanguageCodeFromSession()}/product-detail/${
          item.ProductId
        }/${
          replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ?? "shop"
        }/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`;
        return (
          <div className={ProductListMainClass} key={item.ProductId}>
            <div className="single-product-box">
              <Link
                to={`/${getLanguageCodeFromSession()}/product-detail/${
                  item.ProductId
                }/${
                  replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ??
                  "shop"
                }/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`}
              >
                <div className="product-image">
                  <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    {item?.ProductImagesJson?.map((img, imgIdx) => {
                      return (
                        <img
                          src={adminPanelBaseURL + img.AttachmentURL}
                          alt={img.AttachmentName}
                        />
                      );
                    })}
                  </OwlCarousel>

                  {/* {
                                        item.ProductImagesJson ? <div>
                                            <Link to={urlViewDetailImage} onClick={() => reloadProductDetail(item.ProductId, item.CategoryName, item.ProductName)}>
                                                <img src={adminPanelBaseURL + item.ProductImagesJson[1].AttachmentURL} alt='image' />
                                            </Link>
                                        </div> : null
                                    } */}
                </div>
              </Link>

              {/* <div className='overImage'>
                    <div className='symbol'>
                        <img src={symbol} />
                        </div>
                        <div className='wishlist'>
                        </div>
                    </div> */}
              <div className="product-content">
                <p className="carName">
                  <div>
                    <Link
                      to={`/${getLanguageCodeFromSession()}/product-detail/${
                        item.ProductId
                      }/${
                        replaceWhiteSpacesWithDashSymbolInUrl(
                          item.CategoryName
                        ) ?? "shop"
                      }/${replaceWhiteSpacesWithDashSymbolInUrl(
                        item.ProductName
                      )}`}
                    >
                      {/* <img src={symbol} /> */}

                      {item.ProductName.length > 20
                        ? item.ProductName.substr(0, 20) + "..."
                        : item.ProductName}
                    </Link>
                  </div>
                  <div
                    className="wishlist"
                    onClick={(e) => {
                      e.preventDefault();
                      HandleCustomerWishList(
                        item.ProductId,
                        item.ProductName,
                        item.Price,
                        item.DiscountedPrice,
                        item.DiscountId,
                        item.IsDiscountCalculated,
                        item.CouponCode,
                        item?.ProductImagesJson[0]?.AttachmentURL != undefined
                          ? item?.ProductImagesJson[0]?.AttachmentURL
                          : ""
                      );
                    }}
                  >
                    <BsFillHeartFill size={15} />
                  </div>
                </p>

                <div className="price">
                  {item.Price != undefined && item.Price > 0 ? (
                    <>
                      <div className="perDay">
                        <p>
                          {" "}
                          {GetDefaultCurrencySymbol()} {item.WeeklyPrice}
                          <span>/ week.</span>
                        </p>
                        <span>{item.WeekKm} km</span>
                      </div>
                      <div className="perMonth">
                        <p>
                          AED {item.MonthlyPrice} <span>/ month.</span>
                        </p>
                        <span>{item.MonthKm} km</span>
                      </div>
                    </>
                  ) : (
                    <>
                      {GetDefaultCurrencySymbol()}
                      {item.price}
                    </>
                  )}
                </div>
                <ul className="carDetails">
                  <li>{item.CategoryName}</li>
                  <li>
                    <img src="/brands/brefcase.svg" />2
                  </li>
                  <li>
                    <img src="/brands/door.svg" />2
                  </li>
                  <li>
                    <img src="/brands/seat.svg" />2
                  </li>
                </ul>
                <div className="shopDetails d-flex">
                  <div className="shopLogo">
                    <img
                      src={
                        adminPanelBaseURL +
                        item.VendorImagesJson[0].AttachmentURL
                      }
                      alt={item.VendorImagesJson[0].AttachmentName}
                    />
                  </div>
                  <ul className="rentCondition">
                    <li>
                      <BsCheckCircleFill color="green" />
                      {item.MinimumDayBooking} day rental available
                    </li>
                    <li>
                      <BsFillInfoCircleFill color="orange" />
                      Deposite : AED {item.SecurityDeposit}
                    </li>
                    <li>
                      <BsCheckCircleFill color="green" />
                      {item.Insured
                        ? "Insurance included"
                        : "Insurance not included"}
                    </li>
                  </ul>
                </div>
                <li className="depoInfoText">
                  Deposite will be refunded after 25 days
                </li>
                {/* <button> */}
                <Link
                  className="btn btn-secondory"
                  id="lbl_prd_gridone_viewdetail"
                  to={`/${getLanguageCodeFromSession()}/product-detail/${
                    item.ProductId
                  }/${
                    replaceWhiteSpacesWithDashSymbolInUrl(item.CategoryName) ??
                    "shop"
                  }/${replaceWhiteSpacesWithDashSymbolInUrl(item.ProductName)}`}
                >
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "View Detail",
                        "lbl_prd_gridone_viewdetail"
                      )
                    : "Book now"}
                </Link>
                {/* </button> */}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ProductsGridTypeOne;

import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

// Define the styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
  },
  container: {
    padding: 10,
    marginBottom: 10,
  },
  header: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: 10,
  },
  invoiceText: {
    fontSize: 24,
    textAlign: "center",
  },
  dataRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  label: {
    fontWeight: "bold",
  },
  table: {
    flexDirection: "column",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tableHeader: {
    backgroundColor: "#000",
    color: "white",
    padding: 5,
  },
  tableData: {
    padding: 5,
  },
});

const InvoicePDF = ({ customerName, orderId, date, product }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <Text style={styles.header}>Invoice</Text>
          <View style={styles.dataRow}>
            <Text style={styles.label}>Invoice to:</Text>
            <Text>{customerName}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.label}>Order ID:</Text>
            <Text>{orderId}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.label}>Date:</Text>
            <Text>{date}</Text>
          </View>
        </View>

        <View style={styles.container}>
          <Text style={styles.header}>Items</Text>
          <View style={styles.tableRow}>
            <Text style={styles.tableHeader}>PRODUCT ID.</Text>
            <Text style={styles.tableHeader}>DESCRIPTIONS</Text>
            <Text style={styles.tableHeader}>QTY</Text>
            <Text style={styles.tableHeader}>PRICE</Text>
            <Text style={styles.tableHeader}>AMOUNT</Text>
          </View>
          <View style={styles.tableRow}>
            {product.map((el) => {
              return (
                <>
                  <Text style={styles.tableData}>{el.ProductID}</Text>
                  <Text style={styles.tableData}>{el.ProductName}</Text>
                  <Text style={styles.tableData}>{el.Quantity}</Text>
                  <Text style={styles.tableData}>{el.Price}</Text>
                  <Text style={styles.tableData}>{el.OrderItemTotal}</Text>
                </>
              );
            })}
          </View>
        </View>

        <View style={styles.container}>
          <Text style={styles.header}>Total Amount</Text>
          <Text style={styles.tableData}>Total: 11,500 INR</Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDF;

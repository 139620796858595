import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import { useSelector, useDispatch } from "react-redux";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  showErrorMsg,
  showInfoMsg,
  showSuccessMsg,
  validateAnyFormField,
} from "../../../helpers/ValidationHelper";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
  GetLoadSTRPPublishable,
} from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import rootAction from "../../../stateManagment/actions/rootAction";
import OrderSummary from "../../components/cart/OrderSummary";
import {
  makePriceRoundToTwoPlaces,
  makeProductShortDescription,
} from "../../../helpers/ConversionHelper";
import { Helmet } from "react-helmet";

import {
  countDaysBetweenDates,
  formatDateToYYYYMMDD,
  generateUUID,
  GetDefaultCurrencyCode,
  GetDefaultCurrencySymbol,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import { BsXLg } from "react-icons/bs";
import LoadingScreen from "../../components/shared/LoadingScreen";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderPlaceResponse, setOrderPlaceResponse] = useState();
  const [paymentMethod, setPaymentMethod] = useState(7); //-- 6  is cash on delivery -- 7 is Hylobiz
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [CartSubTotal, setCartSubTotal] = useState(0);
  const [ShippingSubTotal, setShippingSubTotal] = useState(0);
  const [OrderTotal, setOrderTotal] = useState(0);
  const [OrderTotalAfterDiscount, setOrderTotalAfterDiscount] = useState(0);
  const [cartProductsData, setCartProductsData] = useState(0);
  const [CouponCode, setCouponCode] = useState("");
  const [noOfDays, setnoOfDays] = useState();
  const [IsCouponCodeApplied, setIsCouponCodeApplied] = useState(false);

  console.log(noOfDays);

  const [IsAlreadyDiscountApplied, setIsAlreadyDiscountApplied] =
    useState(false);
  const [CouponCodeCssClass, setCouponCodeCssClass] =
    useState("cart-coupon-code");

  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  //-->> Form data
  const [OrderNote, setOrderNote] = useState("");
  const [PickUpLocation, setPickUpLocation] = useState("");
  const [DropOffLocation, setDropOffLocation] = useState("");
  const [PickupDate, setPickupDate] = useState(new Date());
  const [DropOffDate, setDropOffDate] = useState(new Date());
  const [CountryID, setCountryID] = useState("");
  const [CountriesList, setCountriesList] = useState([]);
  const [StatesProvincesList, setStatesProvincesList] = useState([]);
  const [CitiesList, setCitiesList] = useState([]);
  const [StateProvinceId, setStateProvinceId] = useState("");
  const [CityId, setCityId] = useState("");
  //--<< Form data

  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const [loginUser, setLoginUser] = useState(
    JSON.parse(loginUserDataJson ?? "{}")
  );

  const cartJsonDataSession = useSelector(
    (state) => state.cartReducer.cartItems
  );
  const cartItemsSession = JSON.parse(cartJsonDataSession ?? "[]");

  if (
    loginUser == undefined ||
    loginUser.UserID == undefined ||
    loginUser.UserID < 1
  ) {
    navigate("/" + getLanguageCodeFromSession() + "/login");
  }

  if (
    cartItemsSession == undefined ||
    cartItemsSession == null ||
    cartItemsSession.length < 1
  ) {
    showInfoMsg("Your cart is empty");
    navigate("/" + getLanguageCodeFromSession() + "/");
  }

  const HandleStateProvinceChagne = async (value) => {
    if (value != undefined) {
      await setStateProvinceId(value);

      //--load city data when state changed
      await LoadCitiesData(value);
    }
  };

  const HandleCountryChange = async (value) => {
    if (value != undefined) {
      await setCountryID(value);
      console.log(value, "COuntry id");

      //--load state province data
      await LoadStateProvinceData(value);
    }
  };

  const LoadCitiesData = async (StateValue) => {
    const headersCity = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramCity = {
      requestParameters: {
        StateProvinceId: StateValue ?? StateProvinceId,
        recordValueJson: "[]",
      },
    };

    //--Get cities list
    const responseCities = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_CITIES_LIST"],
      null,
      paramCity,
      headersCity,
      "POST",
      true
    );
    if (responseCities != null && responseCities.data != null) {
      await setCitiesList(JSON.parse(responseCities.data.data));
    }
  };

  const LoadStateProvinceData = async (CountryValue) => {
    const headersStateProvince = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramStateProvince = {
      requestParameters: {
        CountryId: CountryValue ?? CountryID,
        recordValueJson: "[]",
      },
    };

    //--Get state province list
    const responseStatesProvince = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_STATES_PROVINCES_LIST"],
      null,
      paramStateProvince,
      headersStateProvince,
      "POST",
      true
    );
    console.log(responseStatesProvince);
    if (responseStatesProvince != null && responseStatesProvince.data != null) {
      await setStatesProvincesList(
        JSON.parse(responseStatesProvince.data.data)
      );
    }
  };

  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const paramCountry = {
        requestParameters: {
          recordValueJson: "[]",
        },
      };

      //--Get countreis list
      const responseCountries = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_COUNTRIES_LIST"],
        null,
        paramCountry,
        headers,
        "POST",
        true
      );
      if (responseCountries != null && responseCountries.data != null) {
        await setCountriesList(JSON.parse(responseCountries.data.data));
      }

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["Signup"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    dataOperationInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  const GetCouponCodeInfo = async () => {
    if (IsCouponCodeApplied) {
      showInfoMsg("Coupon code is already applied!");
      return false;
    }

    let isValid = validateAnyFormField(
      "Coupon Code",
      CouponCode,
      "text",
      null,
      30,
      true
    );
    if (isValid == false) {
      return false;
    }

    const headersCoupon = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramCoupon = {
      requestParameters: {
        CouponCode: CouponCode,
        cartJsonData: JSON.stringify(cartItemsSession),
      },
    };

    const couponResponse = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_COUPON_CODE_DISCOUNT"],
      Config["COMMON_CONTROLLER_SUB_URL"],
      paramCoupon,
      headersCoupon,
      "POST",
      true
    );

    if (couponResponse != null && couponResponse.data != null) {
      let copounData = JSON.parse(couponResponse.data.data);
      console.log(copounData);
      if (
        copounData != undefined &&
        copounData.DiscountValueAfterCouponAppliedWithQuantity != undefined &&
        copounData.DiscountValueAfterCouponAppliedWithQuantity > 0
      ) {
        setOrderTotalAfterDiscount(
          OrderTotal - copounData.DiscountValueAfterCouponAppliedWithQuantity ??
            0
        );
        setIsCouponCodeApplied(true);
      } else {
        showErrorMsg("Invalid coupon code!");
      }
    }
  };

  const handleCheckoutOnSubmit = async (e) => {
    e.preventDefault();
    const sixCharacterUUID = generateUUID();
    console.log(sixCharacterUUID);
    console.log(typeof paymentMethod, "<=== payment method");
    if (paymentMethod === "6") {
      PlaceAndConfirmCustomerOrder("", sixCharacterUUID);
    } else if (paymentMethod === 7) {
      // get current screen url
      const URL = window.location.href;

      //pass query in url to check validation
      const queryString = `?id=${sixCharacterUUID}`;
      const currentURL = URL + queryString;

      //get current date and add two days in it
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 2);
      const formattedDate = formatDateToYYYYMMDD(currentDate);
      const amount = CartSubTotal.toString();

      try {
        if (
          OrderNote &&
          PickupDate &&
          PickUpLocation &&
          DropOffLocation &&
          noOfDays
        ) {
          console.log("true");
          const finalPrice = amount * noOfDays;
          console.log(amount);
          console.log(finalPrice);
          dispatch(rootAction.commonAction.setLoading(true));
          let isYes = window.confirm("Do you really want place order?");
          if (isYes) {
            const headers = {
              Accept: "application/json",
              "Content-Type": "application/json",
            };

            const params = {
              orderId: sixCharacterUUID,
              amount: finalPrice.toString(),
              customer_name: loginUser.FirstName + loginUser.FirstName,
              customer_mobile: loginUser.MobileNo,
              customer_email: loginUser.EmailAddress,
              currency: "AED",
              expire_by: 0,
              sms_notify: false,
              email_notify: true,
              partial_payment: false,
              additional_field1: "",
              additional_field2: "",
              redirect_url: currentURL,
              dueDate: formattedDate,
            };

            console.log(params);

            const orderPlaceResponse = await MakeApiCallAsync(
              Config.END_POINT_NAMES["DONE_PAYMENT"],
              Config["COMMON_CONTROLLER_SUB_URL"],
              params,
              headers,
              "POST",
              true
            );

            const respJson = JSON.parse(orderPlaceResponse.data);
            console.log(respJson);
            if (respJson.code != "0") {
              showErrorMsg(respJson.message);
              dispatch(rootAction.commonAction.setLoading(false));
            } else {
              window.location.href = respJson.short_url;
              showSuccessMsg(respJson.message);
            }
          } else {
            //--stop loader
            setTimeout(() => {
              dispatch(rootAction.commonAction.setLoading(false));
            }, LOADER_DURATION);
          }
        } else {
          console.log("false");
          showErrorMsg("Please fill all fields and try again!");
        }

        // let isValid = false;
        // let validationArray = [];

        // isValid = validateAnyFormField(
        //   "Any detail",
        //   OrderNote,
        //   "text",
        //   null,
        //   800,
        //   true
        // );

        // //--check if any field is not valid
        // if (validationArray != null && validationArray.length > 0) {
        //   isValid = false;
        //   return false;
        // } else {
        //   isValid = true;
        // }

        // if (isValid == false) {
        //   validationArray.push({
        //     isValid: isValid,
        //   });
        // } else if (isValid == true) {

        // }
      } catch (err) {
        showErrorMsg("An error occured. Please try again!");
        console.log(err.message);
        //--stop loader
        setTimeout(() => {
          dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);
      }
    }
  };

  useEffect(() => {
    // Function to check if the id exists and run your desired function
    const validateIdAndRunFunction = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const idParam = urlParams.get("id");

      // Check if the id exists
      if (idParam) {
        // Call your desired function here
        // For example, if your function is named "handleIdValidation"
        checkStatus(idParam);
      }
    };
    validateIdAndRunFunction();
  }, []);

  const checkStatus = async (id) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    try {
      const response = await MakeApiCallAsync(
        `hylobiz/getstatus/${id}`,
        "api/v1/common/",
        null,
        headers,
        "GET",
        true
      );
      const respJson = JSON.parse(response.data);
      console.log(respJson.status);
      if (respJson.status == "APPROVED") {
        PlaceAndConfirmCustomerOrder(response.data, id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const PlaceAndConfirmCustomerOrder = async (hyloBizOrderConfirmJson, ID) => {
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(true));
    }, LOADER_DURATION);

    try {
      const headers = {
        // Accept: "application/json",
        "Content-Type": "application/json",
      };

      const params = {
        requestParameters: {
          CouponCode: IsCouponCodeApplied == true ? CouponCode : "",
          DropOffLocation: DropOffLocation,
          OrderNote: OrderNote,
          PaymentMethod: paymentMethod === 7 ? 7 : 6,
          PaymentOrderId: ID,
          PickUpLocation: PickUpLocation,
          PaymentToken: "",
          PickupDate: PickupDate,
          DropOffDate: DropOffDate,
          UserID: loginUser.UserID,
          cartJsonData: JSON.stringify(cartItemsSession),
          hyloBizOrderConfirmJson:
            paymentMethod === 7 ? hyloBizOrderConfirmJson : "",
          recordValueJson: "[]",
        },
      };
      console.log(params);

      const postOrder = await MakeApiCallAsync(
        Config.END_POINT_NAMES["POST_CUSTOMER_ORDER"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        params,
        headers,
        "POST",
        true
      );

      console.log(postOrder);
      if (postOrder.data.statusCode === 200) {
        setTimeout(() => {
          dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);
        const respJson = JSON.parse(postOrder.data.data);
        showSuccessMsg("Order place successfully");
        console.log(respJson[0].OrderId);
        navigate(
          "/" +
            getLanguageCodeFromSession() +
            `/order-status/success/${respJson[0].OrderId}`
        );
        dispatch(rootAction.commonAction.setLoading(false));
        //--clear customer cart
        setTimeout(function () {
          dispatch(rootAction.cartAction.setCustomerCart("[]"));
          dispatch(rootAction.cartAction.SetTotalCartItems(0));
          localStorage.setItem("cartItems", "[]");
        }, 1000);
      } else {
        console.log(postOrder);
        setTimeout(() => {
          dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);
      }
    } catch (err) {
      showErrorMsg("An error occured. Please try again!");
      console.log(err);

      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      //--Get cart data
      const paramCart = {
        requestParameters: {
          cartJsonData: JSON.stringify(cartItemsSession),
          recordValueJson: "[]",
        },
      };

      const customerCartResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_CUSTOMER_CART_ITEMS"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        paramCart,
        headers,
        "POST",
        true
      );
      if (customerCartResponse != null && customerCartResponse.data != null) {
        let finalData = JSON.parse(customerCartResponse.data.data);
        console.log(finalData);

        if (finalData != null) {
          setTimeout(() => {
            setCartProductsData(finalData.productsData);
            setCartSubTotal(finalData.cartSubTotal);
            setShippingSubTotal(finalData.shippingSubTotal);
            setOrderTotal(finalData.orderTotal);

            if (
              finalData.productsData.length > 0 &&
              finalData.productsData.some((el) => el.DiscountedPrice > 0)
            ) {
              setIsAlreadyDiscountApplied(true);
            }
          }, 500);
        }
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    dataOperationInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["Checkout"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
    console.log(event.target.value);
  };

  console.log(cartProductsData);
  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Checkout</title>
        <meta name="description" content={siteTitle + " - Checkout"} />
        <meta name="keywords" content="Checkout"></meta>
      </Helmet>

      <SiteBreadcrumb title="Checkout" />

      <section className="checkout-area ptb-60">
        <div className="container">
          {/* <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="user-actions">
                                <i className="fas fa-sign-in-alt"></i>
                                <span>Returning customer? <Link to="#">Click here to login</Link></span>
                            </div>
                        </div>
                    </div> */}

          <form onSubmit={handleCheckoutOnSubmit}>
            <div className="row">
              <div className="col-lg-5 col-md-5">
                <div className="billing-details">
                  <h3 className="title" id="lbl_check_billdetail">
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Customer Details",
                          "lbl_check_billdetail"
                        )
                      : "Customer Details"}
                  </h3>

                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Country",
                                "lbl_check_country"
                              )
                            : "Country"}
                        </label>
                        <input
                          type="text"
                          name="CountryName"
                          className="form-control"
                          value={loginUser.CountryName}
                          onChange={(e) => {
                            setLoginUser({
                              ...loginUser,
                              CountryName: e.target.value,
                            });
                          }}
                        />
                      </div>

                      {/* <div className="form-group">
                    <label>
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Country",
                            "lbl_singup_country"
                          )
                        : "Country"}
                      <span className="required-field">*</span>
                    </label>
                    <div className="select-box">
                      <select
                        className="form-control"
                        name="CountryID"
                        required={true}
                        value={loginUser.CountryName}
                        onChange={(e) => HandleCountryChange(e.target.value)}
                      >
                        <option value="-999">Select Country</option>
                        {CountriesList?.map((item, idx) => (
                          <option value={item.CountryID}>
                            {item.CountryName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "First Name",
                                "lbl_check_fname"
                              )
                            : "First Name"}
                        </label>
                        <input
                          type="text"
                          name="FirstName"
                          className="form-control"
                          value={loginUser.FirstName}
                          onChange={(e) => {
                            setLoginUser({
                              ...loginUser,
                              FirstName: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Last Name",
                                "lbl_check_lname"
                              )
                            : "Last Name"}
                        </label>
                        <input
                          type="text"
                          name="LastName"
                          className="form-control"
                          value={loginUser.LastName}
                          onChange={(e) => {
                            setLoginUser({
                              ...loginUser,
                              LastName: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Shipping Address",
                                "lbl_check_shipadrs"
                              )
                            : "Shipping Address"}
                        </label>
                        <input
                          type="text"
                          name="address"
                          className="form-control"
                          value={loginUser.AddressLineOne}
                          onChange={(e) => {
                            setLoginUser({
                              ...loginUser,
                              AddressLineOne: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "City",
                                "lbl_check_city"
                              )
                            : "City"}
                        </label>
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          value={loginUser.CityName}
                          onChange={(e) => {
                            setLoginUser({
                              ...loginUser,
                              CityName: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "State / Province",
                                "lbl_check_province"
                              )
                            : "State / Province"}
                        </label>
                        <input
                          type="text"
                          name="state"
                          className="form-control"
                          value={loginUser.StateName}
                          onChange={(e) => {
                            setLoginUser({
                              ...loginUser,
                              StateName: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Postcode / Zip",
                                "lbl_check_postcode"
                              )
                            : "Postcode / Zip"}
                        </label>
                        <input
                          type="text"
                          name="zip"
                          className="form-control"
                          value={loginUser.PostalCode}
                          onChange={(e) => {
                            setLoginUser({
                              ...loginUser,
                              PostalCode: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Email Address",
                                "lbl_check_email"
                              )
                            : "Email Address"}
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={loginUser.EmailAddress}
                          onChange={(e) => {
                            setLoginUser({
                              ...loginUser,
                              EmailAddress: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Phone",
                                "lbl_check_phone"
                              )
                            : "Phone"}
                        </label>
                        <input
                          type="number"
                          name="phone"
                          maxLength={10}
                          className="form-control"
                          value={loginUser.MobileNo}
                          onChange={(e) => {
                            setLoginUser({
                              ...loginUser,
                              MobileNo: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 col-md-7">
                <div className="billing-details">
                  <h3 className="title" id="lbl_check_bookdetail">
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Booking Detail",
                          "lbl_check_bookdetail"
                        )
                      : "Booking Detail"}
                  </h3>

                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Pickup Location",
                                "lbl_check_pick_loc"
                              )
                            : "Pickup Location"}
                        </label>
                        <input
                          type="text"
                          name="PickUpLocation"
                          className="form-control"
                          onChange={(e) => setPickUpLocation(e.target.value)}
                          value={PickUpLocation}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Drop-off Location",
                                "lbl_check_drop_loc"
                              )
                            : "Drop-off Location"}
                        </label>
                        <input
                          type="text"
                          name="DropOffLocation"
                          className="form-control"
                          onChange={(e) => setDropOffLocation(e.target.value)}
                          value={DropOffLocation}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Pickup Date",
                                "lbl_check_pick_date"
                              )
                            : "Pickup Date"}
                        </label>

                        <input
                          type="date"
                          name="PickupDate"
                          min={today}
                          className="form-control"
                          onChange={(e) => setPickupDate(e.target.value)}
                          value={PickupDate}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 product-quantity">
                      {/* <div className="input-counter">
                        <span
                          className="minus-btn"
                          onClick={() => {
                            setnoOfDays(-noOfDays);
                          }}
                        >
                          <i className="fas fa-minus"></i>
                        </span>
                        <input
                          type="text"
                          value={noOfDays}
                          min="1"
                          max={10}
                          readOnly={true}
                          onChange={(e) => e}
                        />
                        <span
                          className="plus-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            setnoOfDays(+noOfDays);
                          }}
                        >
                          <i className="fas fa-plus"></i>
                        </span>
                      </div> */}
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "No. of Days",
                                "lbl_check_pick_date"
                              )
                            : "No. of Days"}
                        </label>

                        <input
                          type="number"
                          name="DropOffDate"
                          className="form-control"
                          onChange={(e) => setnoOfDays(e.target.value)}
                          value={noOfDays}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Any Detail",
                                "lbl_check_any_detail"
                              )
                            : "Any Detail"}
                        </label>

                        <textarea
                          name="OrderNote"
                          id="OrderNote"
                          cols="30"
                          rows="6"
                          placeholder="Any Detail"
                          className="form-control"
                          value={OrderNote}
                          onChange={(e) => setOrderNote(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="payment-method">
                    <div className="row login-form">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Payment Method",
                                  "lbl_check_paymethod"
                                )
                              : "Payment Method"}
                            {/* Payment Method */}
                          </label>
                          <select
                            value={paymentMethod}
                            onChange={handlePaymentMethodChange}
                            className="form-control"
                            name="paymentMethod"
                          >
                            <option value="7">
                              {LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Credit Card",
                                    "lbl_check_creditcard"
                                  )
                                : "Credit Card"}
                              {/* Credit Card */}
                            </option>
                            <option value="6">
                              {LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Cash on delivery",
                                    "lbl_check_cashondelivery"
                                  )
                                : "Cash on delivery"}
                              {/* Cash on delivery */}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="lbl_check_placeorder"
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Place Order",
                            "lbl_check_placeorder"
                          )
                        : "Place Order"}
                    </button>
                  </>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

      <BestFacilities />
    </>
  );
};

export default Checkout;

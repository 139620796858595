import React,{ useEffect, useState } from "react";
import Image from "next/image";
import Config from "../../../helpers/Config";
import { AiOutlineSearch } from "react-icons/ai";
import {
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
  getLanguageCodeFromSession,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const BannerSlider = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  useEffect(() => {
    const getDataInUseEffect = async () => {
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["BannerSlider"],
        null
      );
      console.log(arryRespLocalization);
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    getDataInUseEffect();
  }, []);

  return (
    <div className="main-banner">
      {/* <img width={2000} src="/home-banner-website.jpg" /> */}
      <div className="main-banner-content">
        <div className="textContent">
          <h1>
          {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Rent a car in Dubai",
                        "lbl_bannerslider_reanttitle"
                      )
                    : "Rent a car in Dubai"}
            {/* Rent a car in Dubai */}
            </h1>
          <h5>
            {" "}
            {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Book directly from local suppliers. No commission, no mark-ups.",
                        "	lbl_bannerslider_reantinfo"
                      )
                    : "Book directly from local suppliers. No commission, no mark-ups."}
            {/* Book directly from local suppliers. No commission, no mark-ups.*/}
          </h5>
        </div>
        <div className="searchBox">
          <form action="">
            <div className="inputGroup">
              <AiOutlineSearch />
              <input type="text" placeholder="Search Car Rentals in Dubai" />
            </div>
            <button className="btn">
            {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "View all cars",
                        "	lbl_bannerslider_viewcarbutton"
                      )
                    : "View all cars"}
              {/* View all cars */}
              </button>
          </form>
        </div>
        <p>
        {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Download the DXB Mobile App",
                        "lbl_bannerslider_downloadmobileapp"
                      )
                    : "Download the DXB Mobile App"}
          {/* Download the DXB Mobile App */}
          </p>
      </div>
    </div>
  );
};

export default BannerSlider;

import React, { useState, useEffect } from "react";
import Config from "../../../helpers/Config";
import dynamic from "next/dynamic";
import User from "../../resources/themeContent/images/user.png";
const OwlCarousel = dynamic(import("react-owl-carousel3"));

const Testimonial = () => {
  const [display, setDisplay] = useState(true);
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);

  const options = {
    loop: true,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    items: 1,
    navText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>",
    ],
  };

  useEffect(() => {}, []);

  return (
    <>
      <section className="testimonials-area ptb-60">
        <div className="container">
          <div className="section-title">
            <h2> Testimonials</h2>
            <p>
              The experiences shared by our distinguished users have always
              helped us up our game. The DXB car rent Marketplace is often
              reengineered as we follow a "Listen &gt; Understand &gt; Improve"
              cycle.
            </p>
          </div>
          <OwlCarousel
            className="testimonials-slides owl-carousel owl-theme"
            {...options}
          >
            <div className="single-testimonials">
              <div className="client-image">
                <img src={User} alt="image" />
              </div>

              <p>
                I recently rented a car from DXB Car Rent, and I am extremely
                pleased with my experience. The entire process was quick,
                convenient, and stress-free. The staff was friendly and
                professional, and the car I rented was in excellent condition. I
                highly recommend DXB Car Rent for their exceptional service and
                top-notch vehicles. Thank you, DXB Car Rent, for making my
                rental experience a breeze!
              </p>

              <div className="client-info">
                <h4>Adnan Zafar</h4>
                <span>CEO, Collaborics GL (FZE)</span>
              </div>
            </div>

            <div className="single-testimonials">
              <div className="client-image">
                <img src={User} alt="image" />
              </div>

              <p>
                I recently rented a car from DXB Car Rent, and I couldn't be
                happier with my decision. The entire process was seamless and
                efficient, from the reservation to the return. The car was
                clean, comfortable, and reliable, making my trip a breeze. DXB
                Car Rent's friendly and helpful staff added to the overall
                positive experience. I highly recommend DXB Car Rent for their
                exceptional service and quality vehicles. Thank you, DXB Car
                Rent, for making my rental experience a great one!
              </p>

              <div className="client-info">
                <h4>Ramy K</h4>
                <span></span>
              </div>
            </div>

            <div className="single-testimonials">
              <div className="client-image">
                <img src={User} alt="image" />
              </div>

              <p>
                I sincerely want to thank the team at DXB Car Rent for the
                quality of their service and attention to detail. Had been in
                Dubai last week on a Business Trip and opted to hire from DXB
                Car Rent as they had the lowest prices online. I have to say
                despite being “cheap in price”, the vehicle and customer support
                was of the highest order. Best part about the whole experience
                was that they did not throw any hidden charges my way at the end
                of contract period. I highly recommend them for all your Car
                Rental needs in Dubai.
              </p>

              <div className="client-info">
                <h4>Ali Al Abood</h4>
                <span></span>
              </div>
            </div>
            <div className="single-testimonials">
              <div className="client-image">
                <img src={User} alt="image" />
              </div>

              <p>
                It was my first time in Dubai, and I knew I had to get a car
                rental as traveling by public transport was not a very
                attractive choice for me. That’s when I came across DXB Car
                Rent. They have an impressive range of vehicles to choose from,
                and each car is kept in the best condition. Even the car that
                they offered me was sparkling clean and almost as good as new.
                They also had a pick-up provision, which was extremely useful
                for me as I didn’t personally have to deliver it back to them. I
                highly recommend them, not only because of their cheap car
                rental rates but also due to their efficient service.
              </p>

              <div className="client-info">
                <h4>Jason Ven</h4>
                <span></span>
              </div>
            </div>
            <div className="single-testimonials">
              <div className="client-image">
                <img src={User} alt="image" />
              </div>

              <p>
                I found out about DXB Car Rent from various travel blogs and
                decided to book a car here for 5 days in Mauritius. I am very
                satisfied with the booking process, the support before, during
                and after the trip. Handover and return in the hotel worked
                great. You don't have to go to a car rental company. The car was
                great too. So five stars, I can recommend DXB Car Rent 100%
              </p>

              <div className="client-info">
                <h4>Nour Saad</h4>
                <span></span>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default Testimonial;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProductQuickView from "./ProductQuickView";
import Config from "../../../helpers/Config";
import ProductRatingStars from "./ProductRatingStars";
import {
  AddCustomerWishList,
  AddProductToCart,
} from "../../../helpers/CartHelper";
import rootAction from "../../../stateManagment/actions/rootAction";
import { useSelector, useDispatch } from "react-redux";
import {
  makeProductShortDescription,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import {
  GetDefaultCurrencySymbol,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import {
  BsCheckCircleFill,
  BsStarFill,
  BsFillInfoCircleFill,
} from "react-icons/bs";
import { FaWhatsapp, FaPhoneAlt, FaComment } from "react-icons/fa";
import vendrLogo from "../../resources/themeContent/images/vendorlogo.jpeg";
import dynamic from "next/dynamic";
const OwlCarousel = dynamic(import("react-owl-carousel3"));

const ProductSearchPage = (props) => {
  const options = {
    loop: true,
    nav: false,
    dots: true,
    // autoplay: true,
    // autoplayTimeout: 1000,
    // autoplayHoverPause: true,
    items: 1,
    navText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>",
    ],
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ProductListMainClass, setProductListMainClass] = useState(
    props.ProductListMainClass
  );
  const [ProductsList, setProductsListGrid] = useState([]);
  const [adminPanelBaseURL, setBaseUrl] = useState(Config["ADMIN_BASE_URL"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  const HandleAddToCart = (ProductID, ProductName, Price, defaultImage) => {
    let cartItems = AddProductToCart(
      ProductID,
      ProductName,
      Price,
      0,
      "",
      0,
      "",
      1,
      defaultImage
    );

    // reduxStore.dispatch(rootAction.cartAction.setCustomerCart(cartItems));
    // reduxStore.dispatch(rootAction.cartAction.SetTotalCartItems(JSON.parse(cartItems).length));

    dispatch(rootAction.cartAction.setCustomerCart(cartItems));
    dispatch(
      rootAction.cartAction.SetTotalCartItems(JSON.parse(cartItems).length)
    );
  };

  const reloadProductDetail = (_productId, _categoryName, _productName) => {
    let productDetailUrlFromForceReload = `/${getLanguageCodeFromSession()}/product-detail/${_productId}/${
      replaceWhiteSpacesWithDashSymbolInUrl(_categoryName) ?? "shop"
    }/${replaceWhiteSpacesWithDashSymbolInUrl(_productName)}`;

    if (
      props.ProductDetailPageForceUpload != undefined &&
      props.ProductDetailPageForceUpload != null &&
      props.ProductDetailPageForceUpload == true &&
      _productId != undefined
    ) {
      navigate(productDetailUrlFromForceReload, { replace: true });
      window.location.reload();
    }
  };

  const HandleCustomerWishList = (
    ProductID,
    ProductName,
    Price,
    DiscountedPrice,
    DiscountId,
    IsDiscountCalculated,
    CouponCode,
    defaultImage
  ) => {
    let customerWishList = AddCustomerWishList(
      ProductID,
      ProductName,
      Price,
      DiscountedPrice,
      DiscountId,
      IsDiscountCalculated,
      CouponCode,
      0,
      "",
      0,
      "",
      1,
      defaultImage
    );

    //--store in storage
    localStorage.setItem("customerWishList", customerWishList);
    dispatch(rootAction.cartAction.setCustomerWishList(customerWishList));
  };

  useEffect(() => {
    setProductsListGrid(props.ProductsList);
  }, [props.ProductsList]);

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["ProductsGridTypeOne"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  useEffect(() => {
    const getDataInUseEffect = async () => {
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["AllProduct"],
        null
      );
      console.log(arryRespLocalization);
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    getDataInUseEffect();
  }, []);

  return (
    <>
      {ProductsList?.map((item, idx) => {
        return (
          <Link
            to={`/en/product-detail/${item.ProductId}/${item.CategoryName}/${item.ProductName}`}
          >
            {/* <div className="row searchPageProduct">
              <div className="col-4 searchImage">
                <OwlCarousel className="owl-carousel owl-theme" {...options}>
                  {item?.ProductImagesJson?.map((img, imgIdx) => {
                    return (
                      <img
                        src={adminPanelBaseURL + img.AttachmentURL}
                        alt="image"
                      />
                    );
                  })}
                </OwlCarousel>
              </div>
              <div className="col-8 d-flex flex-column justify-content-between">
                <div className="d-flex gap-3">
                  <div className="col-6 p-0 ">
                    <div className="d-flex flex-column align-items-start p-3 justify-content-center">
                      <p className="carName">
                        {item.ProductName.length > 20
                          ? item.ProductName.substr(0, 20) + "..."
                          : item.ProductName}
                      </p>
                      <ul className="carDetails">
                        <li>{item.CategoryName}</li>
                        <li>
                          <img src="/brands/brefcase.svg" />2
                        </li>
                        <li>
                          <img src="/brands/door.svg" />2
                        </li>
                        <li>
                          <img src="/brands/seat.svg" />2
                        </li>
                      </ul>
                    </div>
                    <div className=" d-flex align-items-center justify-content-start gap-2 p-3">
                      <div className="shopLogo">
                        <img src={vendrLogo} />
                        
                      </div>
                      <ul className="rentCondition d-flex flex-column gap-3 p-0 m-0">
                        <li className="d-flex align-items-center gap-2">
                          <BsCheckCircleFill color="green" size={20} />1 day
                          rental available
                        </li>
                        <li className="d-flex align-items-center gap-2">
                          <BsFillInfoCircleFill color="orange" size={20} />
                          Deposite : AED{" "}
                          {item.CategoryID === 1018 ? 2000 : 1000}
                        </li>
                        <li className="d-flex align-items-center gap-2">
                          <BsCheckCircleFill color="green" size={20} />
                          Insurance included
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-6 p-3 d-flex flex-column gap-3">
                    <div>
                      <p className="m-0 fs-4 text-orange">
                        
                        {GetDefaultCurrencySymbol()} {item.Price}
                        <span className=" fs-6 text-black">/ day.</span>
                      </p>
                      <span className="text-black fs-6">250 km</span>
                    </div>
                    <div>
                      <p className="m-0 fs-4 text-orange">
                        {GetDefaultCurrencySymbol()} 4500
                        <span className=" fs-6 text-black">/ mo.</span>
                      </p>
                      <span className="text-black fs-6">4500 km</span>
                    </div>
                    <ProductRatingStars Rating={ProductsList.Rating} />
                    <li className="depoInfoText ">
                      Deposite will be refunded after 25 days
                    </li>
                  </div>
                </div>

                <div className="row gap-1">
                  <button className="col-3 bg-eee text-black text-center fs-6 border-0 p-2">
                    <FaPhoneAlt size={25} color="green" />
                  </button>
                  <button className="col-3 bg-eee text-black text-center fs-6 border-0 p-2">
                    <FaWhatsapp size={25} color="#25D366" />
                  </button>
                  <button className="col-3 bg-eee text-black text-center fs-6 border-0 p-2">
                    <FaComment size={25} color="black" />
                  </button>
                </div>
              </div>
            </div> */}

            <div className="row searchPageProduct">
              <div className="col-xl-4 col-sm-12 searchImage">
                <OwlCarousel className="owl-carousel owl-theme" {...options}>
                  {item?.ProductImagesJson?.map((img, imgIdx) => {
                    return (
                      <img
                        src={adminPanelBaseURL + img.AttachmentURL}
                        alt="image" 
                        className="car_image"
                      />
                    );
                  })}
                </OwlCarousel>
              </div>
              <div className="col-xl-8 col-sm-12  d-flex flex-column justify-content-between">
                <div className="row">
                  <div className="d-flex gap-3">
                    <div className="col-6 p-0 ">
                      <div className="d-flex flex-column align-items-start p-3 justify-content-center">
                        <p className="carName">
                          {item.ProductName.length > 20
                            ? item.ProductName.substr(0, 20) + "..."
                            : item.ProductName}
                        </p>
                        <ul className="carDetails">
                          <li>{item.CategoryName}</li>
                          <li>
                            <img src="/brands/brefcase.svg" />2
                          </li>
                          <li>
                            <img src="/brands/door.svg" />2
                          </li>
                          <li>
                            <img src="/brands/seat.svg" />2
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-6 p-3 d-flex flex-column gap-3 ">
                      <div className="aedWeek ">
                        <p className="m-0 fs-4 text-orange">
                          AED {item.WeeklyPrice}
                          <span className="fs-6 text-black">/ week.</span>
                        </p>
                        <span className="text-black fs-6">
                          {item.WeekKm} km
                        </span>
                      </div>
                      <div className="aedWeek">
                        <p className="m-0 fs-4 text-orange">
                          AED {item.MonthlyPrice}
                          <span className=" fs-6 text-black">/ mo.</span>
                        </p>
                        <span className="text-black fs-6">
                          {item.MonthKm} km
                        </span>
                      </div>
                      <ProductRatingStars Rating={ProductsList.Rating} />
                      <li className="depoInfoText ">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Deposite will be refunded after 25 days",
                              "lbl_allprd_deporefunded"
                            )
                          : "Deposite will be refunded after 25 days"}
                        {/* Deposite will be refunded after 25 days */}
                      </li>
                    </div>
                  </div>

                  <div className="row d-flex my-3">
                    <div className="col-6">
                      <div className="shopDetails d-flex align-items-center justify-content-start gap-2 p-3">
                        <div className="shopLogo">
                          <img
                            src={
                              adminPanelBaseURL +
                              item.VendorImagesJson[0].AttachmentURL
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 ">
                      <ul className="rentCondition d-flex flex-column gap-3 p-0 m-0">
                        <li className="d-flex align-items-center gap-2">
                          <BsCheckCircleFill color="green" size={20} />
                          {item.MinimumDayBooking}
                          {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              " day rental available",
                              "lbl_allprd_dayavailable"
                            )
                          : " day rental available"}
                           {/* day rental available */}
                        </li>
                        <li className="d-flex align-items-center gap-2">
                          <BsFillInfoCircleFill color="orange" size={20} />
                          
                          {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              " Deposite",
                              "lbl_allprd_deposite"
                            )
                          : " Depositee"}
                          {/* Deposite */}
                          : AED {item.SecurityDeposit}
                        </li>
                        <li className="d-flex align-items-center gap-2">
                          <BsCheckCircleFill color="green" size={20} />
                          {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              " Insurance included",
                              "lbl_allprd_insurance"
                            )
                          : " Insurance included"}
                          {/* Insurance included */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="shopContact row justify-content-sm-start">
                  <button className="col-3 bg-eee text-black text-center fs-6 border-0 p-2">
                    <FaPhoneAlt size={25} color="green" />
                  </button>
                  <button className="col-3 bg-eee text-black text-center fs-6 border-0 p-2">
                    <FaWhatsapp size={25} color="#25D366" />
                  </button>
                  <button className="col-3 bg-eee text-black text-center fs-6 border-0 p-2">
                    <FaComment size={25} color="black" />
                  </button>
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </>
  );
};

export default ProductSearchPage;

import React, { useEffect, useState } from "react";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import { Helmet } from "react-helmet";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Config from "../../../helpers/Config";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const FaqPage = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      let lnCode = getLanguageCodeFromSession();
      setLangCode(lnCode);

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["FaqPage"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  useEffect(() => {
    const getDataInUseEffect = async () => {
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["FaqPage"],
        null
      );
      console.log(arryRespLocalization);
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    getDataInUseEffect();
  }, []);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Frequently Asked Questions(FAQ)</title>
        <meta
          name="description"
          content={siteTitle + " - Frequently Asked Questions (FAQ)"}
        />
        <meta
          name="keywords"
          content="Frequently Asked Questionsok, FAQ"
        ></meta>
      </Helmet>

      <SiteBreadcrumb title="FAQ's" />

      <section className="faq-area ptb-60">
        <div className="container">
          <div className="section-title">
            <h2>
              <span id="lbl_faq_pagetitle">
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      "Frequently Asked Questions",
                      "lbl_faq_pagetitle"
                    )
                  : "Frequently Asked Questions"}
              </span>
            </h2>
          </div>

          <div className="faq-accordion">
            <ul className="accordion">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is the best mode of transport in Dubai?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Whether you’re here for a holiday or residing in the UAE,
                      rental cars offer the much-needed flexibility and
                      convenience. The world-class infrastructure Dubai has to
                      offer can be experienced the right way only with a car.
                      You can rent a car based on your budget, preference,
                      requirement and even have it delivered to your location.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is the cost of car rental in Dubai?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The cost of car rental in Dubai depends on the type of
                      car, the model as well as the duration you want to rent it
                      for. However, the average cost can range from AED 1500 per
                      month for an economy car such as Nissan Sunny and upto AED
                      3500 per day for high-end sports cars.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do I book a rental car in Dubai?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Browse live car rental offers on DXB car rent. Contact any
                      of the listed car rental companies by phone or WhatsApp
                      directly. Suggest your required car and resent your
                      documents. Delivery is usually available at your
                      convenience. It's as easy as that!
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What are my overheads (additional costs) in renting a car?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Your overheads may include salik (toll), fuel and parking
                      on your own as per your usage. Delivety and pick-up for
                      the rental car might be charged extra.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is the minimum eligible age to rent a car in the UAE?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The legal age limit is 21 years and above to rent a car
                      across the IJAE. However, iMs completely dependent on the
                      car rental company you're hiring from. Some car rental
                      companies require the customer to be 23 years of age or
                      atnve while some even allow 18 year Olds to hire a car.
                      Please use the "minimum age required" filter on our
                      marketplace to find the car for you.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What are the benefits of renting a car on monthly-basis in
                      Dubai?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ul>
                      <li>
                        {" "}
                        Much cheaper and faster than using public transport.
                      </li>
                      <li>
                        {" "}
                        Cost efficient as you dont pay the rising insurance and
                        registration costs.
                      </li>
                      <li>
                        {" "}
                        As good as your own car without the hassle of
                        maintaining it.
                      </li>
                      <li>
                        {" "}
                        Monthly rates are heavily discounted as compared to
                        day-basis rentals. Choice of multiple suppliers offering
                        competitive rates and package deals.
                      </li>
                      <li> Option to upgrade or downgrade every month.</li>
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I rent a car without a credit card?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, some car rental companies accept car rental payment
                      as well as security deposit by cash and/or debit card.
                      However, security deposit by credit card as a
                      pre-authorization block is recommended as it's
                      automatically released by the bank after 30 days.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can anyone else drive the rental car other than me?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Only the renter is allowed to drive the rental car as the
                      car insurance is assigned to his name. However, an
                      additional driver can be added to the insurance coverage
                      allowing him/her to drive the same car as well. Be sure to
                      do this at the start of rental for which additional
                      charges may apply
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I hire a driver for my rental car?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Safe Driver Service is available for your car to driven
                      for a flat fee of AED 80 across Dubai. Alternatively, if
                      you need a car with a driver service, check out our
                      Chauffeur Service options for you. Service is available
                      24x7 across the UAE.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How can I find rent a car near me?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The easiest way to find car rentals near you is, of
                      course, through Google or Gcogle Maps. However,
                      adnanzafar-001-site3.htempurl.com takes your car rental
                      search to another level as you can browse live car rental
                      offers near you. You can further filter offers by Shop
                      Locations and Delivery Available in your locality. Simply
                      search by your area name e.g. A1 Quoz or Business Bay. You
                      can also use the filter feature on our marketplace and
                      search a car based on type, model, price and more!
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I rent a car with a new driving license in the UAE?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      If you have a new driving license that's less than six
                      months old, please check With the car rental agent as the
                      requirement varies based on the insurance policy of the
                      respective car. Some are available for new driving license
                      holders at a higher security deposit amount, with limited
                      insurance coverage and liable for higher excess fee while
                      others are only available with older licenses. All cars
                      can be rented with a valid driving license older than six
                      months.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I rent a car for AED 500 per month in Dubai?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The lowest available monthly car rental options start from
                      AED 1490 on DXB car rent. Special seasonal offers bring
                      down the prices to AED 1290 per month but they are hard to
                      come by and might include hidden charges. This is mainly
                      t«ause it's not feasible for a licensed car rental company
                      to provide a car at a lower rate. In case you're getting a
                      car for an unbelievable AED 500 monthly rental price, its
                      too gocd to be true. Please be cautious of such offers and
                      the car rental companies you're planning to hire from,
                      especially since you will be putting down a security
                      deposit of AED 1000 or so which is to be refunded 30 days
                      after you return the car.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </ul>
          </div>
        </div>
      </section>

      <BestFacilities />
    </>
  );
};

export default FaqPage;

import React, { useEffect, useState } from "react";
import BannerSlider from "../../components/home/BannerSlider";
import PopularProducts from "../../components/products/PopularProducts";
import CompaignSection from "../../components/shared/CompaignSection";
import NewProducts from "../../components/products/NewProducts";
import PopularCategories from "../../components/shared/PopularCategories";
import TopBrands from "../../components/shared/TopBrands";
import Testimonial from "../../components/shared/Testimonial";
import SubscribeNewsLetter from "../../components/shared/SubscribeNewsLetter";
import { Link, useNavigate } from "react-router-dom";
import Config from "../../../helpers/Config";
import FaqPage from "../faq/FaqPage";
import { BsCheckLg } from "react-icons/bs";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import { Helmet } from "react-helmet";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const Home = () => {
  const navigate = useNavigate();
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  const locations = [
    "Abu Dhabi",
    "Sharjah",
    "Dubai Marina",
    "International City",
    "Business Bay",
    "JLT",
    "Al Barsha",
    "Deira",
    "Al Karama",
    "Bur Dubai",
    "Ras Al Khaimah",
    "Ajman",
    "Al Quoz",
    "Al Qusais",
    "Al Nahda",
    "Discovery Gardens",
    "Silicon Oasis",
    "Abu Hail",
    "Sheikh Zayed Road",
    "JBR",
    "Palm Jumeirah",
    "Satwa",
    "Fujairah",
    "Umm Al Quwain",
    "Al Ain",
    "Dubai Airport",
  ];

  // useEffect(() => {
  //   // declare the data fetching function
  //   const dataOperationFunc = async () => {
  //     let lnCode = getLanguageCodeFromSession();
  //     setLangCode(lnCode);

  //     //-- Get website localization data
  //     let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
  //       GlobalEnums.Entities["FaqPage"],
  //       null
  //     );
  //     if (
  //       arryRespLocalization != null &&
  //       arryRespLocalization != undefined &&
  //       arryRespLocalization.length > 0
  //     ) {
  //       await setLocalizationLabelsArray(arryRespLocalization);
  //     }
  //   };
  //   // call the function
  //   dataOperationFunc().catch(console.error);
  // }, []);
  const handleBannerButtonClickUrl = (url) => {
    if (url != undefined && url != null && url.length > 0) {
      // window.location.href = url;
      navigate(url);
    } else {
      return false;
    }
  };

  useEffect(() => {
    const getDataInUseEffect = async () => {
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["FaqPage"],
        null
      );
      console.log(arryRespLocalization);
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    getDataInUseEffect();
  }, []);

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Home</title>
        <meta name="description" content={siteTitle + " - Home"} />
        <meta name="keywords" content="Home"></meta>
      </Helmet>

      <BannerSlider handleBannerButtonClickUrl={handleBannerButtonClickUrl} />
      <PopularCategories />
      <TopBrands />
      <NewProducts
        title={"Latest Car Rental Offers in Dubai"}
        subtitle={
          "Choose among cars with in-demand driving features and high mileage, and rent a car at the best price. Exclusive car rental discounts, updated seasonally!"
        }
      />
      <CompaignSection />
      <NewProducts
        title={"Chauffeur Service in Dubai"}
        subtitle={
          "Our luxury chauffeur services include Point to Point, Airport Transfer and Hourly basis, provided by our hand-picked and exclusive partners."
        }
      />
      <Testimonial />

      <PopularProducts />
      <div className="companyInfo container">
        <div className="section-title">
          <h2>
            <span>Find the best car rental company for you</span>
          </h2>
        </div>
        <hr />
        <div className="row">
          <ul>
            <li>
              Company name is the first-ever global{" "}
              <b>car rental and leasing marketplace.</b> We work with 150+ local
              car rental companies in Dubai. You can choose among their 2000+
              verified cars to find the best rental car for you.
            </li>
            <li>
              Find cheap car rental deals and discounts for all types of cars:
              be it for personal or business use. Access competitive,
              commission-free car rental service in <b>Dubai</b>,{" "}
              <b>Abu Dhabi</b>, <b>Sharjah</b> and <b>Ajman</b>.
            </li>
            <li>
              Our car rental partners’ fleet include every car you’ve ever
              dreamed of. From high-end supercar rentals such as Ferrari,
              Lamborghini and Rolls Royce to luxury SUVs Range Rover, Mercedes
              Benz and even economy cars such as Kia Picanto, Nissan Sunny and
              Renault Duster.
            </li>
          </ul>
        </div>
      </div>

      <div className="container">
        <div className="section-title">
          <div className="row">
            <div className="col-6">
              <h2 style={{ float: "left" }}>
                <span id="lbl_rcntprd_title">
                  Documents Required for Car Rental in the UAE
                </span>{" "}
              </h2>
            </div>
          </div>
          <hr />
          <p>
            If you’re planning a trip to the UAE, you’ll find that all major
            attractions in the UAE are spread far and wide. From unique shopping
            destinations like the Mall Of The Emirates, popular landmarks such
            as the Sheikh Zayed Grand Mosque in Abu Dhabi to exquisite hotels
            and resorts located in Ras Al Khaimah, the best way to get around is
            by car. You are eligible to rent a car across the emirates provided
            you have the below mentioned valid documents with you:
          </p>
        </div>
        <div className="required-docs ">
          <div className="border">
            <div className="heading">
              <p>For UAE Residents</p>
            </div>
            <div className="content">
              <img src="/uae-resident.jpg" alt="" />
              <ul>
                <li className="d-flex align-items-center gap-2">
                  <BsCheckLg color="orange" />
                  UAE Driving License
                </li>
                <li className="d-flex align-items-center gap-2">
                  <BsCheckLg color="orange" /> Emirates ID (Residential Visa may
                  be acceptable) <br />
                  {/* (Residential Visa may be acceptable) */}
                </li>
              </ul>
            </div>
          </div>
          <div className="border">
            <div className="heading">
              <p>For Tourists visiting the UAE</p>
            </div>
            <div className="content">
              <img src="/uae-resident.jpg" alt="" />
              <ul>
                <li className="d-flex align-items-center gap-2">
                  {" "}
                  <BsCheckLg color="orange" /> Passport
                </li>
                <li className="d-flex align-items-center gap-2">
                  {" "}
                  <BsCheckLg color="orange" /> Visit Visa
                </li>
                <li className="d-flex align-items-center gap-2">
                  {" "}
                  <BsCheckLg color="orange" /> Home Country Driving License
                </li>
                <li className="d-flex align-items-center gap-2">
                  {" "}
                  <BsCheckLg color="orange" /> International Driving Permit
                  (IDP)
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p>
          Visitors from the GCC, US, UK, Canada, Europe and certain other
          countries can drive with their home country driving license,without
          the need of an IDP.
        </p>
      </div>

      <div className="bg-[#4d4d4d]">
        <div className="container p-4">
          <div className="container mb-5">
            <h2>Important tips for renting a car in Dubai</h2>
            <hr />
            <ul>
              <li className="mb-4">
                Choose a company that’s located near you or one that offers
                ‘fast delivery’ in your location if you need the car with
                pick-up and delivery service
              </li>
              <li className="mb-4">
                At the time of pick up, check for existing dents and scratches.
                Best to shoot a video circling the car and take close-up
                pictures of existing damages, if any, share them with the
                service provider instantly to avoid any misunderstandings that
                may arise later
              </li>
              <li className="mb-4">
                Always best to provide the security deposit by credit card as a
                pre-authorization block, which automatically releases after
                20-30 days from the end date of your rental
              </li>
              <li className="mb-4">
                Please be sure to sign the car rental agreement issued under the
                same company name as advertised on DXB car rent
              </li>
              <li className="mb-4">
                Incase of any issue with the selected car rental company, you
                may contact the DXB car rent Team with proof of booking and
                other details
              </li>
            </ul>
          </div>
          <div className="container mb-5">
            <h2>Find cheap car rental deals and discounts</h2>
            <hr />
            <ul>
              <li className="mb-4">
                Renting a car online is always the ideal way to find a cheap
                rent a car in Dubai. However, be sure to check if you’re dealing
                with the actual car rental supplier or an agent. Your cost from
                the direct supplier would naturally be cheaper whereas rental
                rates offered by agents would be higher as they include a
                commission
              </li>
              <li className="mb-4">
                Through DXB car rent, you’re sure to find the cheapest car
                rentals near you in an easy and comprehensive way. You can
                browse live car rental offers from multiple car rental service
                providers and decide to go ahead with the one offering the best
                deal to you
              </li>
            </ul>
          </div>
          <div className="container mb-5">
            <h2>Top benefits for renting a car with a driver in Dubai</h2>
            <hr />
            <p>
              There are a multitude of advantages of hiring a car and driver in
              the Emirates, the top three include:
            </p>
            <ul>
              <li>
                <b>Sit back and relax</b>
              </li>
            </ul>
            <p>
              Let our professional chauffeur drive you and worry about the
              traffic. They know the best routes to make your journey
              comfortable.
            </p>
            <ul>
              <li>
                <b>Pre-decided rates</b>
              </li>
            </ul>
            <p>
              Our prices are transparent. They are all-inclusive of the no. of
              hours booked within the city limits.
            </p>
            <ul>
              <li>
                <b>Top class concierge</b>
              </li>
            </ul>
            <p>
              DXB car rent connects you to only the most reputed and dependable
              chauffeur service companies based in the UAE.
            </p>
          </div>
        </div>
      </div>

      <section className="faq-area ptb-60">
        <div className="container">
          <div className="section-title">
            <h2>
              <span id="lbl_faq_pagetitle">
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      "Frequently Asked Questions",
                      "lbl_faq_pagetitle"
                    )
                  : "Frequently Asked Questions"}
              </span>
            </h2>
          </div>

          <div className="faq-accordion">
            <ul className="accordion">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is the best mode of transport in Dubai?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Whether you’re here for a holiday or residing in the UAE,
                      rental cars offer the much-needed flexibility and
                      convenience. The world-class infrastructure Dubai has to
                      offer can be experienced the right way only with a car.
                      You can rent a car based on your budget, preference,
                      requirement and even have it delivered to your location.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is the cost of car rental in Dubai?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The cost of car rental in Dubai depends on the type of
                      car, the model as well as the duration you want to rent it
                      for. However, the average cost can range from AED 1500 per
                      month for an economy car such as Nissan Sunny and upto AED
                      3500 per day for high-end sports cars.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do I book a rental car in Dubai?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Browse live car rental offers on DXB car rent. Contact any
                      of the listed car rental companies by phone or WhatsApp
                      directly. Suggest your required car and resent your
                      documents. Delivery is usually available at your
                      convenience. It's as easy as that!
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What are my overheads (additional costs) in renting a car?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Your overheads may include salik (toll), fuel and parking
                      on your own as per your usage. Delivety and pick-up for
                      the rental car might be charged extra.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is the minimum eligible age to rent a car in the UAE?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The legal age limit is 21 years and above to rent a car
                      across the IJAE. However, iMs completely dependent on the
                      car rental company you're hiring from. Some car rental
                      companies require the customer to be 23 years of age or
                      atnve while some even allow 18 year Olds to hire a car.
                      Please use the "minimum age required" filter on our
                      marketplace to find the car for you.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What are the benefits of renting a car on monthly-basis in
                      Dubai?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ul>
                      <li>
                        {" "}
                        Much cheaper and faster than using public transport.
                      </li>
                      <li>
                        {" "}
                        Cost efficient as you dont pay the rising insurance and
                        registration costs.
                      </li>
                      <li>
                        {" "}
                        As good as your own car without the hassle of
                        maintaining it.
                      </li>
                      <li>
                        {" "}
                        Monthly rates are heavily discounted as compared to
                        day-basis rentals. Choice of multiple suppliers offering
                        competitive rates and package deals.
                      </li>
                      <li> Option to upgrade or downgrade every month.</li>
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I rent a car without a credit card?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, some car rental companies accept car rental payment
                      as well as security deposit by cash and/or debit card.
                      However, security deposit by credit card as a
                      pre-authorization block is recommended as it's
                      automatically released by the bank after 30 days.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can anyone else drive the rental car other than me?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Only the renter is allowed to drive the rental car as the
                      car insurance is assigned to his name. However, an
                      additional driver can be added to the insurance coverage
                      allowing him/her to drive the same car as well. Be sure to
                      do this at the start of rental for which additional
                      charges may apply
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I hire a driver for my rental car?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Safe Driver Service is available for your car to driven
                      for a flat fee of AED 80 across Dubai. Alternatively, if
                      you need a car with a driver service, check out our
                      Chauffeur Service options for you. Service is available
                      24x7 across the UAE.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How can I find rent a car near me?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The easiest way to find car rentals near you is, of
                      course, through Google or Gcogle Maps. However,
                      adnanzafar-001-site3.htempurl.com takes your car rental
                      search to another level as you can browse live car rental
                      offers near you. You can further filter offers by Shop
                      Locations and Delivery Available in your locality. Simply
                      search by your area name e.g. A1 Quoz or Business Bay. You
                      can also use the filter feature on our marketplace and
                      search a car based on type, model, price and more!
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I rent a car with a new driving license in the UAE?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      If you have a new driving license that's less than six
                      months old, please check With the car rental agent as the
                      requirement varies based on the insurance policy of the
                      respective car. Some are available for new driving license
                      holders at a higher security deposit amount, with limited
                      insurance coverage and liable for higher excess fee while
                      others are only available with older licenses. All cars
                      can be rented with a valid driving license older than six
                      months.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I rent a car for AED 500 per month in Dubai?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The lowest available monthly car rental options start from
                      AED 1490 on DXB car rent. Special seasonal offers bring
                      down the prices to AED 1290 per month but they are hard to
                      come by and might include hidden charges. This is mainly
                      t«ause it's not feasible for a licensed car rental company
                      to provide a car at a lower rate. In case you're getting a
                      car for an unbelievable AED 500 monthly rental price, its
                      too gocd to be true. Please be cautious of such offers and
                      the car rental companies you're planning to hire from,
                      especially since you will be putting down a security
                      deposit of AED 1000 or so which is to be refunded 30 days
                      after you return the car.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </ul>
          </div>
        </div>
      </section>

      <section className="p-5 bg-black">
        <div className="popLocations container">
          <div className="section-title">
            <div className="row">
              <div className="col-6">
                <h2 style={{ float: "left" }} className="text-white">
                  <span>Popular locations</span>
                </h2>
              </div>
              <div className="col-6">
                <div className="products-view-all">
                  <a id="" href="#" className="text-white">
                    View all
                  </a>
                </div>
              </div>
            </div>

            <p></p>
          </div>
          <hr className="bg-white" />

          <div className="content">
            {locations.map((item, index) => (
              <Link to={`/all-products/${item}`}>
                <p key={index} className="text-white">
                  <strong>{item}</strong>
                </p>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Config from "../../../helpers/Config";
import {
  getFileExtensionFromContentType,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import { GetUserIdForHeader } from "../../../helpers/CommonHelper";
import InvoicePDF from "../pdf/Pdf";
import { PDFDownloadLink } from "@react-pdf/renderer";

const OrderDetailModel = ({ closeDetailModel, product }) => {
  const [adminPanelBaseURL, setadminPanelBaseURL] = useState(
    Config["ADMIN_BASE_URL"]
  );

  const downloadDigitalProduct = async (OrderItemID, ProductName) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let UserID = await GetUserIdForHeader();
    let fileUrl =
      Config["ADMIN_BASE_URL"] +
      Config["COMMON_CONTROLLER_SUB_URL"] +
      Config.END_POINT_NAMES["DOWNLOAD_DIGITAL_FILE"];
    fileUrl = `${fileUrl}/${OrderItemID ?? 0}/${UserID}`;

    const response = await fetch(fileUrl, {
      headers: headers,
    });

    const contentType = await response.headers.get("content-type");
    const fileExtension = getFileExtensionFromContentType(contentType);

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    let FileName = replaceWhiteSpacesWithDashSymbolInUrl(ProductName);
    FileName = FileName ?? "Your_Digital_Product";
    link.setAttribute("download", FileName + fileExtension);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  return (
    <div className="orderHistoryDetail" onClick={closeDetailModel}>
      <div className="innerModel">
        {product.map((el, i) => {
          return (
            <div key={i} className="productSection">
              <p className="productName">
                {el.ProductName}
                {i === 0 ? (
                  <span
                    style={{ fontSize: "30px", cursor: "pointer" }}
                    onClick={closeDetailModel}
                  >
                    &times;
                  </span>
                ) : null}
              </p>
              <img src={adminPanelBaseURL + el.DefaultImageUrl} />
              <div className="productDetails">
                <div>
                  <p>
                    Days : <strong>{el.Quantity}</strong>
                  </p>
                  <p>
                    Price : <strong>{el.Price}</strong>
                  </p>
                </div>
                <p>
                  Total : <strong>{el.OrderItemTotal}</strong>
                </p>
              </div>
              <div className="buttonGroup">
                <button className="btn btn-primary d-flex align-items-center gap-3 justify-content-center ">
                  <PDFDownloadLink
                    document={
                      <InvoicePDF
                        customerName={"Name"}
                        orderId={product[0]?.OrderID}
                        date={"DD/MM/YYYY"}
                        product={product}
                      />
                    }
                    fileName={`invoice.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      error ? error : "Download PDF"
                    }
                  </PDFDownloadLink>
                </button>
                {/* <button className="btn btn-primary d-flex align-items-center gap-3 justify-content-center ">
            Mail me
          </button> */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderDetailModel;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import successLottie from "../../resources/lottie/animation_lm2djap1.json";
import { useDispatch } from "react-redux";
import rootAction from "../../../stateManagment/actions/rootAction";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  getFileExtensionFromContentType,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import { GetUserIdForHeader } from "../../../helpers/CommonHelper";
import InvoicePDF from "../../components/pdf/Pdf";
import { PDFDownloadLink } from "@react-pdf/renderer";

const OrderConfirmation = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [OrderItemsDetailList, setOrderItemsDetailList] = useState([]);


  const viewOrderItemsDetails = async (OrderId) => {
    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    const headersDetail = {
      // customerid: userData?.UserID,
      // customeremail: userData.EmailAddress,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramDetail = {
      requestParameters: {
        OrderId: OrderId,
        recordValueJson: "[]",
      },
    };

    let responseDetailOrderDetail = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_CUSTOME_ORDER_HISTORY_DETAIL"],
      null,
      paramDetail,
      headersDetail,
      "POST",
      true
    );
    if (
      responseDetailOrderDetail != null &&
      responseDetailOrderDetail.data != null
    ) {
      await setOrderItemsDetailList(
        JSON.parse(responseDetailOrderDetail.data.data)
      );
      console.log(JSON.parse(responseDetailOrderDetail.data.data));
    }

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);

    try {
      // ScrollIntoSpecificDiv("order_item_detail", "smooth");
      // setIsOrderDetailModelShow(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const downloadDigitalProduct = async (OrderItemID, ProductName) => {
    // debugger;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let UserID = await GetUserIdForHeader();
    let fileUrl =
      Config["ADMIN_BASE_URL"] +
      Config["COMMON_CONTROLLER_SUB_URL"] +
      Config.END_POINT_NAMES["DOWNLOAD_DIGITAL_FILE"];
    fileUrl = `${fileUrl}/${OrderItemID ?? 0}/${UserID}`;

    const response = await fetch(fileUrl, {
      headers: headers,
    });

    const contentType = await response.headers.get("content-type");
    const fileExtension = getFileExtensionFromContentType(contentType);

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    let FileName = replaceWhiteSpacesWithDashSymbolInUrl(ProductName);
    FileName = FileName ?? "Your_Digital_Product";
    link.setAttribute("download", FileName + fileExtension);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    viewOrderItemsDetails(params.id);
  }, []);

  return (
    <div className="p-4 paymentSucessPage mx-5">
      <div className="img">
        <Lottie animationData={successLottie} loop={true} />
      </div>
      <div className="text">
        <h2>Thank you for your purchase!</h2>
        <p>
          Your order <strong>{params.id}</strong> has been successfully
          processed. We've emailed you a receipt.
        </p>
        <div className="buttonGroup">
          <button
            className="btn btn-primary d-flex align-items-center gap-3 justify-content-center "
            onClick={() =>
              downloadDigitalProduct(
                OrderItemsDetailList[0].OrderItemID,
                OrderItemsDetailList[0].ProductName
              )
            }
          >
        <PDFDownloadLink document={<InvoicePDF customerName={"Name"} orderId={OrderItemsDetailList[0]?.OrderID} date={"DD/MM/YYYY"} product={OrderItemsDetailList} />} fileName={`invoice.pdf`}>
          {({ blob, url, loading, error }) => (error ? error : 'Download PDF')}
        </PDFDownloadLink>
          </button>
          <button
            className="btn btn-primary d-flex align-items-center gap-3 justify-content-center "
            onClick={() => navigate("/")}
          >
            Back to home
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;

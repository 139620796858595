import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Link from 'next/link';
import { useSelector, useDispatch } from "react-redux";
import {
  makeAnyStringLengthShort,
  makeProductShortDescription,
} from "../../../helpers/ConversionHelper";
import Config from "../../../helpers/Config";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
} from "../../../helpers/ApiHelpers";
import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
  getLanguageCodeFromSession,
} from "../../../helpers/CommonHelper";
import beyond1 from "../../resources/themeContent/images/beyondCars/car-with-driver.png";
import beyond2 from "../../resources/themeContent/images/beyondCars/yacht-rental.png";
import beyond3 from "../../resources/themeContent/images/beyondCars/safe-diver.png";
import beyond4 from "../../resources/themeContent/images/beyondCars/buggy-rentals.png";
import GlobalEnums from "../../../helpers/GlobalEnums";

const CompaignSection = () => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  const dispatch = useDispatch();
  // const [CampaignList, setCampaignList] = useState([]);
  const [adminPanelBaseURL, setBaseUrl] = useState(Config["ADMIN_BASE_URL"]);

  const CampaignList = [
    {
      id: 1,
      title: "Car with Driver",
      subtitle: " available across UAE",
      // img: "/beyondCars/car-with-driver.webp"
      img: beyond1,
    },
    {
      id: 2,
      title: "Yacht Rental",
      subtitle: " in Dubai",
      img: beyond2,
    },
    {
      id: 3,
      title: "Safe Driver",
      subtitle: " for your car across dubai",
      img: beyond3,
    },
    {
      id: 4,
      title: "Buggy Rentals",
      subtitle: " in Dubai dunes",
      img: beyond4,
    },
  ];
  // useEffect(() => {
  //     // declare the data fetching function
  //     const getCampaignList = async () => {

  //         const headersCampaign = {
  //             // customerid: userData?.UserID,
  //             // customeremail: userData.EmailAddress,
  //             Accept: 'application/json',
  //             'Content-Type': 'application/json',

  //         }

  //         const paramCampaign = {
  //             requestParameters: {
  //                 recordValueJson: "[]",
  //             },
  //         };

  //         const response = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_WEB_CAMPAIGN_LIST'], null, paramCampaign, headersCampaign, "POST", true);
  //         if (response != null && response.data != null) {
  //             await setCampaignList(JSON.parse(response.data.data));
  //             console.log(JSON.parse(response.data.data));
  //         }

  //     }

  //     //--start loader
  //     dispatch(rootAction.commonAction.setLoading(true));

  //     // call the function
  //     getCampaignList().catch(console.error);

  //     //--stop loader
  //     setTimeout(() => {
  //         dispatch(rootAction.commonAction.setLoading(false));
  //     }, LOADER_DURATION);

  // }, [])

  useEffect(() => {
    const getDataInUseEffect = async () => {
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["Campaign"],
        null
      );
      console.log(arryRespLocalization);
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    getDataInUseEffect();
  }, []);

  return (
    <>
      <div className="categories-banner-area pt-60">
        <div className="container">
          <div className="section-title">
            <h2>
              {LocalizationLabelsArray.length > 0
                ? replaceLoclizationLabel(
                    LocalizationLabelsArray,
                    "Beyond Car Rentals",
                    "lbl_compaign_beyondtitle"
                  )
                : "Beyond Car Rentals"}
              {/* Beyond Car Rentals  */}
            </h2>
            <p>
              {LocalizationLabelsArray.length > 0
                ? replaceLoclizationLabel(
                    LocalizationLabelsArray,
                    "Explore our suite of curated services such as chauffeur, buggy and safe driver provided by exclusive partners and managed by DXB Car Rent.",
                    "lbl_compaign_beyondinfo"
                  )
                : "Explore our suite of curated services such as chauffeur, buggy and safe driver provided by exclusive partners and managed by DXB Car Rent."}
              Explore our suite of curated services such as chauffeur, buggy and
              safe driver provided by exclusive partners and managed by DXB Car
              Rent.
            </p>
          </div>

          <div className="row">
            {/* // CampaignList?.map((item, idx) => */}
            {CampaignList.map((item) => {
              return (
                <div className="col-lg-3 col-sm-6" key={item.id}>
                  <Link to={`/all-products/0/${item.title}`}>
                    <div className="offer-categories-box left-categories hover-active">
                      {/* <img src={adminPanelBaseURL + item.CoverPictureUrl} alt="image" height={190}/> */}
                      <img src={item.img} alt="image" height={210} />
                      <div className="beyondBox content">
                        <p>
                          <b>{item.title}</b>
                          {item.subtitle}
                        </p>
                        {/* <Link to={`/${getLanguageCodeFromSession()}/campaign/${item.CampaignId}/${item.MainTitle}`} className="btn btn-primary">View Detail</Link> */}
                      </div>

                      {/* <Link to={`/${getLanguageCodeFromSession()}/campaign/${item.CampaignId}/${item.MainTitle}`}  className="link-btn"> */}

                      {/* </Link> */}
                    </div>
                  </Link>
                </div>
              );
            })}

            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompaignSection;

import React, { Component } from "react";
import TopPanel from "./TopPanel";
import TopHeader from "./TopHeader";
import MegaMenu from "./MegaMenu";
import NewTicker from "../shared/NewTicker";

const Navbar = () => {
  return (
    <>
      {/* <TopPanel /> */}

      <TopHeader />

      <MegaMenu />

      {/* <NewTicker /> */}
    </>
  );
};

export default Navbar;

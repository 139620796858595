import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
} from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import {
  makeAnyStringLengthShort,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import dynamic from "next/dynamic";
const OwlCarousel = dynamic(import("react-owl-carousel3"));

const options = {
  loop: true,
  nav: false,
  dots: true,
  autoplay: true,
  // autoplayTimeout: 300,
  autoplayHoverPause: false,
  navText: [
    "<i class='fas fa-chevron-left'></i>",
    "<i class='fas fa-chevron-right'></i>",
  ],
  items: 9,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  },
};

const TopBrands = () => {
  const [ManufacturerList, setManufacturer] = useState([]);
  const [adminPanelBaseURL, setBaseUrl] = useState(Config["ADMIN_BASE_URL"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  const naviagte = useNavigate();

  useEffect(() => {
    // declare the data fetching function
    const getManufacturer = async () => {
      //--Get language code
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        // customerid: userData?.UserID,
        // customeremail: userData.EmailAddress,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          recordValueJson: "[]",
        },
      };

      // debugger
      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_MANUFACTURER_LIST"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (response != null && response.data != null) {
        setManufacturer(JSON.parse(response.data.data));
        console.log("Popular Categories List:");
        console.log(JSON.parse(response.data.data));
      }

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["PopularCategories"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    // call the function
    getManufacturer().catch(console.error);
  }, []);

  const navigateOnBrandClick = (ManufacturerID, Name, e) => {
    ManufacturerID = ManufacturerID ?? 0;
    Name = Name ?? "all-categories";

    let newPageUrl = `/${getLanguageCodeFromSession()}/all-products-from-brand/${ManufacturerID}/${replaceWhiteSpacesWithDashSymbolInUrl(
      Name
    )}`;

    naviagte(newPageUrl);

    // if (pathName.includes("AllProducts")) {
    //     window.location.href = newPageUrl;
    // } else {
    //     navigate(newPageUrl);
    // }

    //  navigate(newPageUrl);
    e.preventDefault();
  };

  //    render() {
  return (
    <section className="offer-area ptb-60">
      <div className="container">
        <div className="section-title">
          <h2>Rent A Car From Top Brands </h2>
          <p>
            Get on a road-trip now with the best deals for high-end cars
            manufactured by top automobile companies in the world.
          </p>
        </div>

        <div className="row topBrand">
          <div className="topBrandsScroll">
            {ManufacturerList.map((item) => {
              return (
                <Link
                  to="#"
                  onClick={(e) => {
                    navigateOnBrandClick(item.ManufacturerID, item.Name, e);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="col-lg-12 col-md-12" key={item.id}>
                    <div className="single-offer-box">
                      <img
                        src={adminPanelBaseURL + item.AttachmentURL}
                        alt="image"
                      />
                      <div className="offer-content">
                        <h3>
                          {langCode != null &&
                          langCode == Config.LANG_CODES_ENUM["Arabic"]
                            ? item.LocalizationJsonData != null &&
                              item.LocalizationJsonData.length > 0
                              ? makeAnyStringLengthShort(
                                  item.LocalizationJsonData?.find(
                                    (l) =>
                                      l.langId ==
                                      Config.LANG_CODES_IDS_ENUM["Arabic"]
                                  )?.text,
                                  30
                                )
                              : makeAnyStringLengthShort(item.Name, 30)
                            : makeAnyStringLengthShort(item.Name, 30)}
                          {/* {
        
                                                        langCode != null && langCode == Config.LANG_CODES_ENUM["Arabic"]
                                                            ?
                                                            (item.LocalizationJsonData != null && item.LocalizationJsonData.length > 0
                                                                ?
                                                                makeAnyStringLengthShort(item.LocalizationJsonData?.find(l => l.langId == Config.LANG_CODES_IDS_ENUM["Arabic"])?.text, 30)
                                                                :
                                                                makeAnyStringLengthShort(item.Name, 30)
                                                            )
        
                                                            :
                                                            makeAnyStringLengthShort(item.Name, 30)
                                                    } */}
                        </h3>
                        <p>
                          <span style={{ display: "inline-block" }}>
                            {item.TotalProducts}&nbsp;
                          </span>
                          <span
                            style={{ display: "inline-block" }}
                            id="lbl_popct_prd"
                          >
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Products",
                                  "lbl_popct_prd"
                                )
                              : "Products"}

                            {/* {LocalizationLabelsArray.length > 0 ?
                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Products", "lbl_popct_prd")
                                                    :
                                                    "Products"
                                                } */}
                          </span>
                        </p>

                        {
                          // (() => {
                          //     // let allProductsUrl = `/${getLanguageCodeFromSession()}/all-products/${item.CategoryID ?? 0}/${replaceWhiteSpacesWithDashSymbolInUrl(item.Name)}`
                          //     return (
                          //         <>
                          //             {/* <Link to={allProductsUrl} className="shop-now-btn" id="lbl_popct_shopnow"> */}
                          //             {LocalizationLabelsArray.length > 0 ?
                          //                 replaceLoclizationLabel(LocalizationLabelsArray, "Shop Now", "lbl_popct_shopnow")
                          //                 :
                          //                 "Shop Now"
                          //             }
                          //             {/* </Link> */}
                          //         </>
                          //     );
                          // })()
                        }
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
  //    }
};
/*
{this.state.display ? <OwlCarousel 
    className="offer-slides owl-carousel owl-theme"
    {...options}
>

</OwlCarousel> : '' }
*/
export default TopBrands;

import React, { useEffect, useState } from "react";
// import Link from 'next/link';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";

import Partner1Image from "../../resources/themeContent/images/partner1.png";
import Partner2Image from "../../resources/themeContent/images/partner2.png";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
} from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import {
  checkIfStringIsEmtpy,
  showInfoMsg,
} from "../../../helpers/ValidationHelper";
import LoginUserModal from "../modal/LoginUserModal";
import {
  makeAnyStringLengthShort,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import NewTicker from "../shared/NewTicker";

const MegaMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchForm, setSearchForm] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [SearchTerm, setSearchTerm] = useState("");
  const [CategoriesList, setCategoriesList] = useState([]);
  const [ManufacturerList, setManufacturerList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [displayLoginUserModal, setDisplayLoginUserModal] = useState(false);
  const [adminPanelBaseURL, setBaseUrl] = useState(Config["ADMIN_BASE_URL"]);
  const [LogoImageFromStorage, setLogoImageFromStorage] = useState(
    useSelector((state) => state.commonReducer.websiteLogoInLocalStorage)
  );
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");
  const totalCartItems = useSelector(
    (state) => state.cartReducer.totalCartItems
  );

  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");

  const handleCart = (event) => {
    event.preventDefault();

    if (
      totalCartItems !== null &&
      totalCartItems !== null &&
      totalCartItems > 0
    ) {
      navigate("/" + getLanguageCodeFromSession() + "/cart");
    } else {
      showInfoMsg("No item exists in your cart");
    }
  };

  const handleSearchForm = () => {
    setSearchForm(!searchForm);
  };

  const handleOpenCloseLoginUserModal = (event) => {
    event.preventDefault();
    setDisplayLoginUserModal(!displayLoginUserModal);
  };

  const HandleLogout = (e) => {
    localStorage.setItem("user", JSON.stringify("{}"));
    dispatch(rootAction.userAction.setUser("{}"));

    navigate("/", { replace: true });
  };

  const submitSearchForm = () => {
    if (
      SearchTerm !== null &&
      SearchTerm !== undefined &&
      SearchTerm.length > 1
    ) {
      let url =
        "/" + getLanguageCodeFromSession() + "/all-products/" + SearchTerm;
      setSearchForm(!searchForm);

      navigate(url, { replace: true });
      window.location.reload();
    }
  };

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const navigateOnCategoryClick = (CategoryID, categoryName, e) => {
    CategoryID = CategoryID ?? 0;
    categoryName = categoryName ?? "all-categories";

    let newPageUrl = `/${getLanguageCodeFromSession()}/all-products/${CategoryID}/${replaceWhiteSpacesWithDashSymbolInUrl(
      categoryName
    )}`;

    // window.location.href = newPageUrl;
    navigate(newPageUrl);
    window.location.reload();

    // if (pathName.includes("AllProducts")) {
    //     window.location.href = newPageUrl;
    // } else {
    //     navigate(newPageUrl);
    // }

    e.preventDefault();
  };

  const navigateOnBrandClick = (ManufacturerID, Name, e) => {
    ManufacturerID = ManufacturerID ?? 0;
    Name = Name ?? "all-categories";

    let newPageUrl = `/${getLanguageCodeFromSession()}/all-products-from-brand/${ManufacturerID}/${replaceWhiteSpacesWithDashSymbolInUrl(
      Name
    )}`;

    // window.location.href = newPageUrl;
    navigate(newPageUrl);
    window.location.reload();

    // if (pathName.includes("AllProducts")) {
    //     window.location.href = newPageUrl;
    // } else {
    // }

    //  navigate(newPageUrl);
    e.preventDefault();
  };

  const navigateOnLocationClick = (id) => {
    let newPageURL = `/${getLanguageCodeFromSession()}/allProducts/${id}`;

    navigate(newPageURL);
    window.location.reload();
  };

  useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getDataInUseEffect = async () => {
      //--Get language code
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          PageNo: 1,
          PageSize: 100,
          recordValueJson: [],
        },
      };

      //--Get categories list
      const categoriesResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_CATEGORIES_LIST"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (categoriesResponse !== null && categoriesResponse.data !== null) {
        await setCategoriesList(JSON.parse(categoriesResponse.data.data));
        // console.log("Header Menu Categories List:");
        // console.log(JSON.parse(categoriesResponse.data.data));
      }

      //--Get manufacturer list
      const manufacturerResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_MANUFACTURER_LIST"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (manufacturerResponse !== null && manufacturerResponse.data !== null) {
        await setManufacturerList(JSON.parse(manufacturerResponse.data.data));
        // console.log("Header Menu Manufacturer List:");
        // console.log(JSON.parse(manufacturerResponse.data.data));
      }

      //--Get Website Logo
      if (!checkIfStringIsEmtpy(LogoImageFromStorage)) {
        let paramLogo = {
          requestParameters: {
            recordValueJson: [],
          },
        };

        let WebsiteLogoInLocalStorage = "";
        let logoResponse = await MakeApiCallAsync(
          Config.END_POINT_NAMES["GET_WEBSITE_LOGO"],
          null,
          paramLogo,
          headers,
          "POST",
          true
        );
        if (logoResponse !== null && logoResponse.data !== null) {
          console.log(logoResponse.data);

          if (logoResponse.data.data !== "") {
            let logoData = JSON.parse(logoResponse.data.data);
            WebsiteLogoInLocalStorage = logoData[0].AppConfigValue;
            dispatch(
              rootAction.commonAction.setWebsiteLogo(WebsiteLogoInLocalStorage)
            );
            setLogoImageFromStorage(WebsiteLogoInLocalStorage);
          }
        }
      }

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["MegaMenu"],
        null
      );
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    getDataInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  const [dropDown, setDropDown] = useState(false);
  const [dropDownCategories, setDropDownCategories] = useState(false);
  const [dropDownBrands, setDropDownBrands] = useState(false);

  const dropDownShow = (state, value) => {
    state(!value);
  };

  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const paramStateProvince = {
        requestParameters: {
          CountryId: 237,
          recordValueJson: "[]",
        },
      };
      //--Get countreis list
      const responseCountries = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_STATES_PROVINCES_LIST"],
        null,
        paramStateProvince,
        headers,
        "POST",
        true
      );

      if (responseCountries != null && responseCountries.data != null) {
        await setStateList(JSON.parse(responseCountries.data.data));
      }

      // 237
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    dataOperationInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  return (
    <>
      <div className="navbar-area">
        <div id="navbar" className="comero-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link
                to={`/${getLanguageCodeFromSession()}/`}
                className="navbar-brand"
              >
                <img src="/NewLogp.svg" width={155} height={41} alt="logo" />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item p-relative">
                    <Link
                      to={`/${getLanguageCodeFromSession()}/`}
                      className="nav-link active"
                      id="lbl_mgmenu_home"
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Home",
                            "lbl_mgmenu_home"
                          )
                        : "Home"}
                    </Link>
                  </li>

                  <li className="nav-item p-relative">
                    <Link
                      to="#"
                      className="nav-link active"
                      onClick={() => dropDownShow(setDropDown, dropDown)}
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "RENT A CAR",
                            "lbl_mgmenu_reantacar"
                          )
                        : "RENT A CAR"}
                      <i className="fas fa-chevron-down"></i>
                    </Link>
                    {dropDown ? (
                      <ul className="dropdown-menu">
                        {StateList?.map((state) => {
                          return (
                            <li
                              className="nav-item"
                              key={state.StateProvinceID}
                            >
                              <p
                                onClick={() =>
                                  navigateOnLocationClick(state.StateProvinceID)
                                }
                                className="nav-link active"
                              >
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      state.StateName,
                                      "lbl_mgmenu_home"
                                    )
                                  : state.StateName}
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </li>

                  <li className="nav-item megamenu">
                    <Link
                      to="#"
                      className="nav-link"
                      onClick={() =>
                        dropDownShow(setDropDownCategories, dropDownCategories)
                      }
                    >
                      <span id="lbl_mgmenu_category">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Categories",
                              "lbl_mgmenu_category"
                            )
                          : "CAR CATEGORIES"}
                      </span>{" "}
                      <i className="fas fa-chevron-down"></i>
                    </Link>
                    {dropDownCategories ? (
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <div className="container">
                            <div className="row row-cols-lg-5 row-cols-sm-5 row-cols-8">
                              {CategoriesList.map((item, idx) => {
                                return (
                                  <>
                                    <div className="col text-center">
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          navigateOnCategoryClick(
                                            item.CategoryID,
                                            item.Name,
                                            e
                                          );
                                        }}
                                      >
                                        <h6 className="submenu-title nowrap d-flex align-items-center gap-3">
                                          <img
                                            style={{ width: "50px" }}
                                            src={
                                              adminPanelBaseURL +
                                              item.AttachmentURL
                                            }
                                            alt={item.AttachmentName}
                                          />
                                          {item.Name}(
                                          {item.TotalCategoryProducts})
                                        </h6>
                                      </Link>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </li>
                      </ul>
                    ) : null}
                  </li>

                  <li className="nav-item megamenu">
                    <Link
                      to="#"
                      className="nav-link"
                      onClick={() =>
                        dropDownShow(setDropDownBrands, dropDownBrands)
                      }
                    >
                      <span id="lbl_mgmenu_manufacturer">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "CAR BRANDS",
                              "lbl_mgmenu_carbrands"
                            )
                          : "CAR BRANDS"}
                      </span>{" "}
                      <i className="fas fa-chevron-down"></i>
                    </Link>
                    {dropDownBrands ? (
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <div className="container">
                            <div className="row row row-cols-lg-5 row-cols-sm-5 row-cols-8">
                              {ManufacturerList?.map((item, idx) => {
                                if (
                                  ManufacturerList?.filter(
                                    (obj) =>
                                      obj.ManufacturerID === item.ManufacturerID
                                  ).length > 0
                                ) {
                                  return (
                                    <>
                                      <div className="col text-center ">
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            navigateOnBrandClick(
                                              item.ManufacturerID,
                                              item.Name,
                                              e
                                            );
                                          }}
                                        >
                                          <h6 className="submenu-title nowrap d-flex align-items-center gap-3">
                                            <img
                                              style={{ width: "50px" }}
                                              src={
                                                adminPanelBaseURL +
                                                item.AttachmentURL
                                              }
                                              alt={item.AttachmentName}
                                            />
                                            {item.Name} ({item.TotalProducts})
                                          </h6>
                                        </Link>
                                      </div>
                                    </>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </div>
                          </div>
                        </li>
                      </ul>
                    ) : null}
                  </li>
                </ul>

                <div className="others-option">
                  <div className="option-item">
                    <i
                      onClick={handleSearchForm}
                      className="search-btn fas fa-search"
                      style={{
                        display: searchForm ? "none" : "block",
                      }}
                    ></i>

                    <i
                      onClick={handleSearchForm}
                      className={`close-btn fas fa-times ${
                        searchForm ? "active" : ""
                      }`}
                    ></i>

                    <div
                      className="search-overlay search-popup"
                      style={{
                        display: searchForm ? "block" : "none",
                      }}
                    >
                      <div className="search-box">
                        <form
                          className="search-form"
                          onSubmit={() => submitSearchForm()}
                        >
                          <input
                            className="search-input"
                            name="search"
                            placeholder="Search"
                            type="text"
                            value={SearchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          <button
                            className="search-button"
                            type="button"
                            onClick={() => submitSearchForm()}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="option-item">
                    {loginUser != null &&
                    loginUser != undefined &&
                    loginUser.UserID != undefined &&
                    loginUser.UserID > 0 ? (
                      <>
                        <Link
                          to="#"
                          onClick={(e) => {
                            handleOpenCloseLoginUserModal(e);
                          }}
                        >
                          {loginUser.FirstName}
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          to={`/${getLanguageCodeFromSession()}/login`}
                          id="lbl_mgmenu_login"
                        >
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Login",
                                "lbl_mgmenu_login"
                              )
                            : "Login"}
                        </Link>
                      </>
                    )}
                  </div>

                  {loginUser != null &&
                  loginUser !== undefined &&
                  loginUser.UserID !== undefined &&
                  loginUser.UserID > 0 ? (
                    <>
                      <div className="option-item">
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            HandleLogout(e);
                          }}
                          id="lbl_mgmenu_logout"
                        >
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Logout",
                                "lbl_mgmenu_logout"
                              )
                            : "Logout"}
                        </Link>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="option-item">
                    <Link
                      to="#"
                      onClick={(e) => {
                        handleCart(e);
                      }}
                    >
                      <span id="lbl_mgmenu_cart">
                        {LocalizationLabelsArray.length > 0
                          ? replaceLoclizationLabel(
                              LocalizationLabelsArray,
                              "Cart",
                              "lbl_mgmenu_cart"
                            )
                          : "Cart"}
                      </span>
                      ({totalCartItems ?? 0}){" "}
                      <i className="fas fa-shopping-bag"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <NewTicker />
        </div>
      </div>
      {loginUser !== null &&
      loginUser !== undefined &&
      loginUser.UserID !== undefined &&
      loginUser.UserID > 0 &&
      displayLoginUserModal ? (
        <LoginUserModal
          handleOpenCloseLoginUserModal={handleOpenCloseLoginUserModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MegaMenu;

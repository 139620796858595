import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
} from "../../../helpers/ApiHelpers";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";

import Config from "../../../helpers/Config";
import {
  makeAnyStringLengthShort,
  replaceWhiteSpacesWithDashSymbolInUrl,
} from "../../../helpers/ConversionHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

import CPimg1 from "../../resources/themeContent/images/category-product-image/cp-img1.jpg";
import CPimg2 from "../../resources/themeContent/images/category-product-image/cp-img2.jpg";
import CPimg3 from "../../resources/themeContent/images/category-product-image/cp-img3.jpg";

const PopularCategories = () => {
  const [PopularCategoriesList, setPopularCategories] = useState([]);
  const [adminPanelBaseURL, setBaseUrl] = useState(Config["ADMIN_BASE_URL"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // declare the data fetching function
    const getPopularCategories = async () => {
      //--Get language code
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        // customerid: userData?.UserID,
        // customeremail: userData.EmailAddress,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          recordValueJson: "[]",
        },
      };

      // debugger;
      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_CATEGORIES_LIST"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (response != null && response.data != null) {
        setPopularCategories(JSON.parse(response.data.data));
        console.log("Popular Categories List:");
        console.log(JSON.parse(response.data.data));
      }

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["PopularCategories"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    // call the function
    getPopularCategories().catch(console.error);
  }, []);

  const [showAll, setShowAll] = useState(false);

  const navigateOnCategoryClick = (CategoryID, categoryName, e) => {
    CategoryID = CategoryID ?? 0;
    categoryName = categoryName ?? "all-categories";

    let newPageUrl = `/${getLanguageCodeFromSession()}/all-products/${CategoryID}/${replaceWhiteSpacesWithDashSymbolInUrl(
      categoryName
    )}`;

    // window.location.href = newPageUrl;
    navigate(newPageUrl);

    // if (pathName.includes("AllProducts")) {
    //     window.location.href = newPageUrl;
    // } else {
    //     navigate(newPageUrl);
    // }

    //  navigate(newPageUrl);

    e.preventDefault();
  };

  return (
    <>
      <section className="category-boxes-area pt-60">
        <div className="container">
          <div className="section-title">
            <h2>
              <span id="lbl_popct_category">
                {LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      " Popular Categories!",
                      "lbl_popct_category"
                    )
                  : " Find car rental and driver services near you Select City!"}
              </span>
            </h2>
          </div>

          <div className="row">
            {/* {
                            PopularCategoriesList?.map((item, idx) => */}
            {PopularCategoriesList.map((item, index) => {
              if (!showAll && index > 7) {
                return null; // Return null to skip rendering for remaining elements if showAll is false
              }
              return (
                <div className="col-lg-3 col-sm-6">
                  <Link
                    to="#"
                    key={item.id}
                    onClick={(e) => {
                      navigateOnCategoryClick(item.CategoryID, item.Name, e);
                    }}
                  >
                    <div className="category-boxes">
                      <div className="img">
                        <img
                          src={adminPanelBaseURL + item.AttachmentURL}
                          alt="image"
                        />
                        {/* <img src={item.img} alt="image" /> */}
                      </div>
                      <div className="content categoryCont">
                        <h3>
                          {/* {item.name} */}
                          {langCode != null &&
                          langCode == Config.LANG_CODES_ENUM["Arabic"]
                            ? item.LocalizationJsonData != null &&
                              item.LocalizationJsonData.length > 0
                              ? makeAnyStringLengthShort(
                                  item.LocalizationJsonData?.find(
                                    (l) =>
                                      l.langId ==
                                      Config.LANG_CODES_IDS_ENUM["Arabic"]
                                  )?.text,
                                  30
                                )
                              : makeAnyStringLengthShort(item.Name, 30)
                            : makeAnyStringLengthShort(item.Name, 30)}
                          ({item.TotalCategoryProducts})
                        </h3>
                        <p>
                          <span style={{ display: "inline-block" }}>
                            {item.TotalProducts} &nbsp;
                          </span>
                          {/* <p ><span style={{ display: "inline-block" }}>{item.count} &nbsp;</span> */}
                          <span
                            style={{ display: "inline-block" }}
                            id="lbl_popct_prd"
                          >
                            {
                              // item.count ? "Cars" : null
                            }
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Products",
                                  "lbl_popct_prd"
                                )
                              : "Cars"}
                          </span>
                        </p>

                        {() => {
                          // let allProductsUrl = `/${getLanguageCodeFromSession()}/all-products/${item.CategoryID ?? 0}/${replaceWhiteSpacesWithDashSymbolInUrl(item.Name)}`
                          return (
                            <>
                              {/* <Link to={allProductsUrl} className="shop-now-btn" id="lbl_popct_shopnow"> */}

                              {LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Shop Now",
                                    "lbl_popct_shopnow"
                                  )
                                : "Shop Now"}

                              {/* </Link> */}
                            </>
                          );
                        }}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}

            {/* )} */}
          </div>
          <div className="showMoreHome">
            <button
              className="btn btn-primary "
              onClick={() => setShowAll(!showAll)}
            >
              {
                !showAll
                  ? LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Show more",
                        "lbl_popct_showmorebutton"
                      )
                    : "Show more"
                  : // "Show more"

                  LocalizationLabelsArray.length > 0
                  ? replaceLoclizationLabel(
                      LocalizationLabelsArray,
                      "Close",
                      "lbl_popct_closebutton"
                    )
                  : "Close"
                // "Close"
              }
            </button>
          </div>
          <div className="row">
            <p>
              Tired of searching for a <b>‘rent a car near me’</b>? You have
              reached just the right place. DXBCarRent.com is a leading car
              rental marketplace in Dubai featuring budget-friendly car hire
              deals from reliable rental car companies in the region. You can
              choose from our extensive inventory of over 2000 vehicles listed
              by trusted car rental businesses in the UAE. Whether you’re a
              tourist looking for a car facility or a resident in search of long
              term rentals, we assure you the cheapest rent cars at the best
              prices starting as low as <b>AED 30 per day</b>.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default PopularCategories;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Config from "../../../helpers/Config";
import {
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
  getLanguageCodeFromSession,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";

const ProductsFilterOptions = (props) => {
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [langCode, setLangCode] = useState("");

  const handleGrid = (evt, e) => {
    props.onClick(e);
    let i, aLinks;
    aLinks = document.getElementsByTagName("a");
    for (i = 0; i < aLinks.length; i++) {
      aLinks[i].className = aLinks[i].className.replace("active", "");
    }
    evt.currentTarget.className += " active";
  };

  useEffect(() => {
    const getDataInUseEffect = async () => {
      let lnCode = getLanguageCodeFromSession();
      await setLangCode(lnCode);

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["ProductFilterOptions"],
        null
      );
      console.log(arryRespLocalization);
      if (
        arryRespLocalization !== null &&
        arryRespLocalization !== undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    getDataInUseEffect();
  }, []);

  return (
    <div className="products-filter-options">
      <div className="row align-items-center">
        {/* <div className="col d-flex">
          <span>View:</span>
          <div className="view-list-row">
            <div className="view-column">
              <Link
                to="#"
                className="icon-view-two"
                onClick={(e) => {
                  e.preventDefault();
                  handleGrid(e, "products-col-two");
                }}
              >
                <span></span>
                <span></span>
              </Link>
              <Link
                to="#"
                className="icon-view-three active"
                onClick={(e) => {
                  e.preventDefault();
                  handleGrid(e, "");
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </Link>
              <Link
                to="#"
                className="icon-view-four"
                onClick={(e) => {
                  e.preventDefault();
                  handleGrid(e, "products-col-four");
                }}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </Link>
              <Link
                to="#"
                className="view-grid-switch"
                onClick={(e) => {
                  e.preventDefault();
                  handleGrid(e, "products-row-view");
                }}
              >
                <span></span>
                <span></span>
              </Link>
            </div>
          </div>
        </div>
         */}
        <div className="col d-flex justify-content-center">
          <p>
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  " Showing ",
                  "lbl_profiloptio_showing"
                )
              : " Showing "}
            {/* Showing  */}
            {props.PageNo}
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  " to ",
                  "lbl_profiloptio_to"
                )
              : " to "}
            {/* to */}
            {props.PageSize}
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  " of ",
                  "lbl_profiloptio_of"
                )
              : " of "}
            {/* of  */}
            {props.TotalRecords}
            {LocalizationLabelsArray.length > 0
              ? replaceLoclizationLabel(
                  LocalizationLabelsArray,
                  " results ",
                  "lbl_profiloptio_results"
                )
              : " results "}
            {/* results */}
          </p>
        </div>
        <div className="col d-flex">
          <span>Show:</span>
          <div className="show-products-number">
            <select onChange={(e) => props.setPageSizeFromProductFilter(e)}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
            </select>
          </div>
          <span>Sort:</span>
          <div className="products-ordering-list">
            <select onChange={(e) => props.setSortByFilter(e)}>
              <option value="">Featured</option>
              <option value="Price ASC">Price Ascending</option>
              <option value="Price DESC">Price Descending</option>
              <option value="Date DESC">Date Ascending</option>
              <option value="Date ASC">Date Descending</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsFilterOptions;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogoImage from "../../resources/themeContent/images/logo.png";
import { useSelector, useDispatch } from "react-redux";
import Config from "../../../helpers/Config";
import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import { checkIfStringIsEmtpy } from "../../../helpers/ValidationHelper";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import { BsApple } from "react-icons/bs";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { RiVisaLine } from "react-icons/ri";
import { FaFacebookF, FaTwitter, FaInstagram, FaTiktok } from "react-icons/fa";
import visa from "../../resources/themeContent/images/visa-png.png";
import unionPay from "../../resources/themeContent/images/union.png";
import masterCard from "../../resources/themeContent/images/mastercard-png.png";

const Footer = () => {
  const dispatch = useDispatch();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [adminPanelBaseURL, setadminPanelBaseURL] = useState(
    Config["ADMIN_BASE_URL"]
  );
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [LogoImageFromStorage, setLogoImageFromStorage] = useState(
    useSelector((state) => state.commonReducer.websiteLogoInLocalStorage)
  );

  useEffect(() => {
    // declare the data fetching function
    const DataOperationFunc = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          recordValueJson: "[]",
        },
      };

      //--Get payment methods
      const responsePaymentMethods = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_PAYMENT_METHODS"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (
        responsePaymentMethods != null &&
        responsePaymentMethods.data != null
      ) {
        await setPaymentMethods(JSON.parse(responsePaymentMethods.data.data));
      }

      //--Get Website Logo
      if (!checkIfStringIsEmtpy(LogoImageFromStorage)) {
        let paramLogo = {
          requestParameters: {
            recordValueJson: "[]",
          },
        };

        let WebsiteLogoInLocalStorage = "";
        let logoResponse = await MakeApiCallAsync(
          Config.END_POINT_NAMES["GET_WEBSITE_LOGO"],
          null,
          paramLogo,
          headers,
          "POST",
          true
        );
        if (logoResponse != null && logoResponse.data != null) {
          console.log(logoResponse.data);

          if (logoResponse.data.data != "") {
            let logoData = JSON.parse(logoResponse.data.data);
            WebsiteLogoInLocalStorage = logoData[0].AppConfigValue;
            dispatch(
              rootAction.commonAction.setWebsiteLogo(WebsiteLogoInLocalStorage)
            );
            setLogoImageFromStorage(WebsiteLogoInLocalStorage);
          }
        }
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    DataOperationFunc().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["Footer"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  return (
    <>
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <div className="logo">
                  <Link to={`/${getLanguageCodeFromSession()}/`}>
                    {/* <img src={adminPanelBaseURL + LogoImageFromStorage} width={155} height={41} alt="logo" /> */}
                    <img
                      src="/NewLogp.svg"
                      width={155}
                      height={41}
                      alt="logo"
                    />
                  </Link>
                </div>

                <p>
                  Find the best deals for budget and luxury / sports car
                  rentals, chauffeur service and driver on hire service.
                  Headquartered in Dubai, our services are available in select
                  cities across the globe.
                </p>
                {/* <div className='downLoadBtn d-flex align-items-center justify-content-between'>
                                    <div className='bg-eee img d-flex align-items-center gap-3 p-2'>
                                        <BsApple size={20} />
                                        <div>
                                            <span>Download on the</span>
                                            <p>App Store</p>
                                        </div>
                                    </div>
                                    <div className='bg-eee img d-flex align-items-center gap-3 p-2'>

                                        <IoLogoGooglePlaystore size={20} />
                                        <div>
                                            <span>Download on the</span>
                                            <p>App Store</p>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3 id="lbl_footr_quicklink">
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Quick Links",
                        "lbl_footr_quicklink"
                      )
                    : "Quick Links"}
                </h3>

                <ul className="quick-links">
                  <li>
                    <Link
                      to={`/${getLanguageCodeFromSession()}/`}
                      id="lbl_footr_home"
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Home",
                            "lbl_footr_home"
                          )
                        : "Home"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/${getLanguageCodeFromSession()}/about`}
                      id="lbl_footr_about"
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "About Us",
                            "lbl_footr_about"
                          )
                        : "About Us"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/${getLanguageCodeFromSession()}/faq`}
                      id="lbl_footr_faq"
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            " Faq's",
                            "lbl_footr_faq"
                          )
                        : " Faq's"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3 id="lbl_footr_info">
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Information",
                        "lbl_footr_info"
                      )
                    : "Information"}
                </h3>

                <ul className="information-links">
                  <li>
                    <Link to={`/${getLanguageCodeFromSession()}/about`}>
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "About Us",
                            "lbl_footr_about"
                          )
                        : "About Us"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/${getLanguageCodeFromSession()}/contact-us`}
                      id="lbl_footr_cont"
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Contact Us",
                            "lbl_footr_cont"
                          )
                        : "Contact Us"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3 id="lbl_footr_contact_title">
                  {LocalizationLabelsArray.length > 0
                    ? replaceLoclizationLabel(
                        LocalizationLabelsArray,
                        "Contact Us",
                        "lbl_footr_cont"
                      )
                    : "Contact Us"}
                </h3>

                <ul className="footer-contact-info">
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                    <span id="lbl_footr_location">
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Location:",
                            "lbl_footr_location"
                          )
                        : "Location:"}
                    </span>{" "}
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Omar Bin Al Khattab St - Deira - Dubai",
                          "lbl_footr_locationaddress"
                        )
                      : "Omar Bin Al Khattab St - Deira - Dubai"}
                    {/* Omar Bin Al Khattab St - Deira - Dubai */}
                  </li>
                  <li>
                    <i className="fas fa-phone"></i>
                    <span id="lbl_footr_callus">
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Call Us:",
                            "lbl_footr_callus"
                          )
                        : "Call Us:"}
                    </span>{" "}
                    <Link to="tel:(+971) 503656186">+971507887913</Link>
                  </li>
                  <li>
                    <i className="far fa-envelope"></i>
                    <span id="lbl_footr_emailus">
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Email Us:",
                            "lbl_footr_emailus"
                          )
                        : "Email Us:"}
                    </span>{" "}
                    <Link to="mailto:support@novine.com">
                      samrentacar.uae@hotmail.com
                    </Link>
                  </li>
                  <li>
                    <i className="fas fa-fax"></i>
                    <span id="lbl_footr_fax">
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Fax:",
                            "lbl_footr_fax"
                          )
                        : "Fax:"}
                    </span>{" "}
                    <Link to="tel:(+971) 503656186">(+971) 503656186</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* 
                <div className="copyright-area">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <p>Copyright &copy; 2023 Gollaborics GL FZE. All Rights Reserved By <a href="https://www.collaboricsgl.com/" target="_blank" rel="Collaborics GL FZE">Collaborics GL FZE</a></p>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <ul className="payment-card">

                                    {
                                        paymentMethods?.map((item, idx) =>


                                            <li key={item.PaymentMethodId}>
                                                <Link to="#">
                                                    <img src={adminPanelBaseURL + item.ImageUrl} alt="image" />
                                                </Link>
                                            </li>

                                        )}



                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}

        <div className="payCard-area">
          <div className="cards">
            <div className="card">
              <img src={visa} />
            </div>
            <div className="card">
              <img src={unionPay} />
              {/* <RiVisaLine /> */}
            </div>
            <div className="card">
              <img src={masterCard} />
              {/* <RiVisaLine /> */}
            </div>
          </div>
          <div className="lang">
            <p>English</p>
            <p>لعربية‏</p>
            <p>Français</p>
            <p>Dutch</p>
            <p>русский</p>
            <p>Türkçe</p>
            <p>Español</p>
            <p>Chinese</p>
            <p>Italian</p>
            <p>German</p>
          </div>
          <p className="text-center">
            Copyright &copy; 2023 DXB Car rent. All Rights Reserved By{" "}
            <a
              href="https://www.collaboricsgl.com/"
              target="_blank"
              rel="Collaborics GL FZE"
            >
              DXB Car rent
            </a>
          </p>
          <div className="socials">
            <a
              href="https://www.facebook.com/people/CAR-RENT-DXB/100090570889110/?mibextid=ZbWKwL"
              target="_blank"
            >
              <div>
                <FaFacebookF size={20} />
              </div>
            </a>
            <a href="https://twitter.com/dxbcarsrent" target="_blank">
              <div>
                <FaTwitter size={20} />
              </div>
            </a>
            <a href="https://www.instagram.com/dxb.carsrent/" target="_blank">
              <div>
                <FaInstagram size={20} />
              </div>
            </a>
            <a href="https://tiktok.com/@dxbcarrent" target="_blank">
              <div>
                <FaTiktok size={20} />
              </div>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
